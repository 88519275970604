import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  Paper,
  Switch,
  Stack,
  Typography,
} from "@mui/material";

import { alpha, useTheme, styled } from "@mui/material/styles";
import useResponsive from "../../hooks/useResponsive";
import { keyframes } from "@mui/system";
import { useSearchParams } from "react-router-dom";

import Iconify from "../iconify";
// import { db } from "../../firebase.js";
// import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useUserAuth } from "../../UserAuthContext";

const shine = keyframes`
  to {
      background-position-x: 10%
   }
`;

export default function SubscribeOffer(props) {
  const theme = useTheme();
  const { user } = useUserAuth();
  const handleSubscribe = () => {};
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [elevation, setElevation] = useState(3);
  const [isChecked, setIsChecked] = useState(false);
  const isDesktop = useResponsive("up", "lg");
  const [planType, setPlanType] = useState("yearly");
  const [searchParams] = useSearchParams();

  const offer = searchParams.get("offer");
  //console.log(offer);

  const cardData = [
    /*{
      plan: "Starter",
      slug: "starter",
      price: "$5",
      planType: "/month",
      features: ["50K chars / 60m"],
    },*/
    {
      plan: "Pro",
      slug: "pro",
      price: "$9",
      price_yearly: "$7",
      price_yearly_total: "$84",
      planType: "/month",
      features: [
        "Online Video Editor",
        "TTS: 100K chars / 2 hours",
        "Cloning: 10K chars / 20 mins",
        "Transcription: 20 mins",
      ],
      canClone: true,
    },
    {
      plan: "Premium",
      slug: "premium",
      price: "$29",
      price_yearly: "$22",
      price_yearly_total: "$264",
      planType: "/month",
      features: [
        "Online Video Editor",
        "TTS: 300K chars / 6 hours",
        "Cloning: 50K chars / 1 hour",
        "Transcription: 1 hour",
        "Subtitle Generator",
      ],
      canClone: true,
    },
    {
      plan: "Ultra",
      slug: "ultra",
      price: "$99",
      price_yearly: "$79",
      price_yearly_total: "$708",
      planType: "/month",
      features: [
        "Online Video Editor",
        "TTS: 1 Million chars / 20 hours",
        "Cloning: 100K chars / 2 hours",
        "Transcription: 10 hours",
        "Subtitle Generator",
      ],
      canClone: true,
    },
  ];

  const prePaidPlans = [
    {
      plan: "Prepaid Pro",
      slug: "ppro",
      price: "$20",
      planType: "Validity: 1 year",
      features: ["TTS: 150K chars / 3 hours", "Voice Cloning"],
      canClone: false,
    },
    {
      plan: "Prepaid Premium",
      slug: "pprem",
      price: "$50",
      planType: " Validity: 1 year",
      features: ["TTS: 500K chars / 10 hours", "Voice Cloning"],
    },
  ];

  const handlePlanTypeChange = (type) => {
    setPlanType(type);
  };

  const handleMouseEnter = (index) => {
    setSelectedCardIndex(index);
    setElevation(8); // Set a higher elevation when hovering
  };

  const handleMouseLeave = () => {
    setElevation(2); // Reset elevation when not hovering
  };

  return (
    <Stack minHeight={!props.tableOnly ? "70vh" : null}>
      <Stack padding={4} spacing={2}>
        {!props.tableOnly && (
          <Stack spacing={2} minHeight={70}>
            <Typography
              variant="h4"
              align="center"
              sx={{ fontWeight: "bolder" }}
            >
              Upgrade to continue
            </Typography>
            <Typography variant="body1" align="center" sx={{ color: "red" }}>
              Insuffient credit to complete this request. Please upgrade to
              continue
            </Typography>
          </Stack>
        )}

        {!props.selectedPlan && (
          <Stack direction="row" alignItems="center" justifyContent="center">
            <ButtonGroup
              size="small"
              variant="outlined"
              aria-label="Pricing options"
            >
              <Button
                sx={{
                  color:
                    planType === "yearly" ? theme.palette.common.white : null,
                }}
                onClick={() => {
                  handlePlanTypeChange("yearly");
                }}
              >
                Yearly &nbsp;
                <Chip
                  size="small"
                  variant="contained"
                  sx={{
                    height: "15px",
                    color: "#FF5722",
                    backgroundColor: alpha("#D32F2F", 0.2),
                  }}
                  label={
                    <Typography
                      sx={{ fontSize: "11px", textTransform: "none" }}
                      variant="subtitle2"
                    >
                      save ~25%
                    </Typography>
                  }
                ></Chip>
              </Button>
              <Button
                sx={{
                  color:
                    planType === "monthly" ? theme.palette.common.white : null,
                }}
                onClick={() => {
                  handlePlanTypeChange("monthly");
                }}
              >
                Monthly
              </Button>
              {/*<Button
                sx={{
                  color:
                    planType === "prepaid" ? theme.palette.common.white : null,
                }}
                onClick={() => {
                  handlePlanTypeChange("prepaid");
                }}
              >
                Prepaid
              </Button>*/}
            </ButtonGroup>
          </Stack>
        )}

        {planType !== "prepaid" && (
          <Stack>
            <Grid mb={2} container spacing={3} justifyContent="center">
              {cardData.map((card, index) => (
                <Grid item key={index}>
                  {/*card.slug === "premium" ? (
                    <Paper elevation={2}>
                      <Stack
                        padding={1}
                        spacing={1}
                        justifyContent="space-around"
                        alignItems="center"
                        sx={{
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          background:
                            "linear-gradient(-45deg, rgb(0, 156, 255) 40%, rgb(103, 196, 255) 50%, rgb(0, 156, 255) 60%)",
                          backgroundSize: "300%",
                          backgroundPositionX: "100%",
                          animation: `${shine} 2s linear infinite`,
                        }}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          Popular 🌟
                        </Typography>
                      </Stack>
                    </Paper>
                  ) : isDesktop ? (
                    <Stack minHeight={10}>
                      <div>&nbsp;</div>
                    </Stack>
                  ) : null*/}

                  {props.selectedPlan && !card.canClone ? null : (
                    <Paper
                      key={index}
                      elevation={index === selectedCardIndex ? elevation : 2}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      // onClick={() => handleNewProject(card.type)}
                      sx={{
                        textAlign: "center",

                        border: `${
                          card.slug === "premium" ? 2 : 1
                        }px double transparent`,
                        backgroundImage:
                          "linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0)), linear-gradient(90deg, rgba(93,26,138,1) 10%, rgba(202,10,10,1) 80%, rgba(233,145,21,1) 100%)",
                        backgroundOrigin: "border-box",
                        backgroundClip: "padding-box, border-box",
                        boxShadow:
                          "rgba(var(--primary-color), 0.5) 0px 0px 20px 0px",
                        borderRadius: "10px",
                      }}
                    >
                      <Stack
                        padding={4}
                        spacing={1}
                        justifyContent="space-around"
                        alignItems="center"
                        minHeight={220}
                      >
                        <Stack alignItems="center" direction="row">
                          <Iconify icon={card.icon} />
                          <Typography sx={{ fontWeight: "bold" }}>
                            {card.plan}
                          </Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center">
                          <Typography variant="h4">
                            {planType === "monthly"
                              ? card.price
                              : card.price_yearly}
                          </Typography>

                          <Typography
                            sx={{ color: "text.secondary" }}
                            variant="caption"
                          >
                            &nbsp; {card.planType}
                          </Typography>
                        </Stack>
                        <Typography
                          sx={{ color: "text.secondary" }}
                          variant="caption"
                        >
                          {planType === "yearly"
                            ? `Billed ${card.price_yearly_total} anually`
                            : ""}
                        </Typography>
                        <Box>
                          <List sx={{ listStyle: "none", padding: 0 }}>
                            {card.features.map((highlight, index) => (
                              <ListItem
                                key={index}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Stack direction="row" spacing={1}>
                                  <Iconify
                                    icon={
                                      highlight === "Voice Cloning" // quick hack to show an x for cheap plans
                                        ? "fe:close"
                                        : "fe:check"
                                    }
                                  />
                                  <Typography
                                    variant="caption"
                                    sx={{ color: "text.secondary" }}
                                  >
                                    {highlight}
                                  </Typography>
                                </Stack>
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                        <Button
                          fullWidth
                          color="primary"
                          variant="text"
                          href={`https://app.acoust.io/subscribe?plan=${
                            card.slug
                            // adds _y to the plan names for yearly
                          }${planType === "yearly" ? `_y` : ""}${
                            // adds offer if there is one
                            offer ? `&offer=${offer}` : ""
                          }`}
                          style={{
                            color: "#fff",
                            minWidth: "150px",
                            borderRadius: "10px",
                            backgroundImage:
                              "linear-gradient(90deg, rgba(93,26,138,1) 10%, rgba(202,10,10,1) 80%, rgba(233,145,21,1) 100%)",
                          }}
                        >
                          Select
                        </Button>
                      </Stack>
                    </Paper>
                  )}
                </Grid>
              ))}
            </Grid>
          </Stack>
        )}

        {!props.selectedPlan && planType === "prepaid" && (
          <Stack mt={2}>
            <Grid container spacing={2} mt={1} justifyContent="center">
              {prePaidPlans.map((card, index) => (
                <Grid item key={index}>
                  <Paper
                    key={index}
                    elevation={index === selectedCardIndex ? elevation : 2}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    // onClick={() => handleNewProject(card.type)}
                    sx={{
                      textAlign: "center",
                      border: (theme) => `solid 1px ${theme.palette.divider}`,
                    }}
                  >
                    <Stack
                      padding={4}
                      spacing={1}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Stack alignItems="center" direction="row">
                        <Iconify icon={card.icon} />
                        <Typography sx={{ fontWeight: "bold" }}>
                          {card.plan}
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <Typography variant="h4">{card.price}</Typography>
                        <Typography
                          sx={{ color: "text.secondary" }}
                          variant="caption"
                        >
                          &nbsp; {card.planType}
                        </Typography>
                      </Stack>
                      <Box>
                        <List sx={{ listStyle: "none", padding: 0 }}>
                          {card.features.map((highlight, index) => (
                            <ListItem
                              key={index}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Stack direction="row" spacing={1}>
                                <Iconify
                                  icon={
                                    highlight === "Voice Cloning" // quick hack to show an x for cheap plans
                                      ? "fe:close"
                                      : "fe:check"
                                  }
                                />
                                <Typography
                                  variant="caption"
                                  sx={{ color: "text.secondary" }}
                                >
                                  {highlight}
                                </Typography>
                              </Stack>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                      <Button
                        fullWidth
                        color="primary"
                        variant="text"
                        href={`https://app.acoust.io/signup?plan=${card.slug}`}
                        style={{
                          color: "#fff",
                          minWidth: "150px",
                          borderRadius: "10px",
                          backgroundImage:
                            "linear-gradient(90deg, rgba(93,26,138,1) 10%, rgba(202,10,10,1) 80%, rgba(233,145,21,1) 100%)",
                        }}
                      >
                        Select
                      </Button>
                    </Stack>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
