import React, { useEffect, useContext, useRef, useState } from "react";
import TimelineLayer from "./timeline-related/TimelineLayer";
import { fabric } from "fabric";
import {
  convertTransitionToKeyframes,
  handlePauseAllMedia,
  handleRewindAllMedia,
  reCalculateTransitionTime,
  reInitKeyframes,
  shouldPlayMedia,
} from "../Animations/utils";
import { setIntervalAsync } from "set-interval-async/dynamic";
import { clearIntervalAsync } from "set-interval-async/fixed";
import TimelineHeader from "./timeline-related/TimelineHeader";
import { maxTimelineLengthInSeconds } from "./timeline-related/TimelineRuler";
import TimelineResizer from "./timeline-related/timelineResizer";
import { CanvasStore } from "../canvas/store/canvasStore";
import Seekbar from "./Seekbar";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

import _ from "lodash";
import { propertiesToInclude } from "../canvas/customFabric";
import { flattenGroupedObjects } from "../canvas/utils/canvasUtils";
import { initializeAnimation } from "../Animations/rekapi/utils";

export const defaultStaticTransition = {
  id: 11,
  title: "Static",
  type: "Static",
  iconName: "icon-animation-middle-transition",
  transitionPosition: "START",
  duration: 1,
};

const timelineHeight = 75;

const TimeLine = ({ onUpdateTimelineUpdate }) => {
  const { activeCanvas } = useContext(CanvasStore);
  const [selectedArtboard, setSelectedArtboard] = useState(null);
  const [seekbarTimeInMs, setSeekbarTimeInMs] = useState(0);
  const [playingAnimation, setPlayingAnimation] = useState(false);
  const [animationDurationInMS, setAnimationDurationInMS] = useState(5000);
  const [activeObject, setActiveObject] = useState(null);
  const [pixelsPerSecond, setPixelsPerSecond] = useState(1000);
  const [, setScrollX] = useState(0);
  const [layers, setLayers] = useState([]);
  // const cancelPlayingAnimation = useRef(false);
  const animationFrame = useRef(0);
  const animationPlayingInterval = useRef(null);
  const layersScrollRef = useRef();
  const playingAnimationRef = useRef(false);
  const scrollLeft = useRef(0);
  const [timelineSize, setTimelineSize] = useState({
    height: timelineHeight,
    seekbarHeight: timelineHeight - 16,
    timeLimitSeekHeight: timelineHeight - 40,
    layerAreaHeight: timelineHeight - 60,
  });

  const [isHovered, setIsHovered] = useState(false);

  const [dragBorder, setDragBorder] = useState(0);

  useEffect(() => {
    if (activeObject) {
      setTimeout(() => {
        if (layersScrollRef.current) {
          layersScrollRef.current.removeEventListener(
            "scroll",
            handleRightLayersScroll
          );
          layersScrollRef.current.scrollLeft = scrollLeft.current;
          layersScrollRef.current.addEventListener(
            "scroll",
            handleRightLayersScroll
          );
        }
      }, 10);
    }
  }, [activeObject]);
  const animatingFrameRef = useRef(null);
  const animationTimelineRef = useRef(null);

  const handleRightLayersScroll = (e) => {
    layersScrollRef.current.scrollTop = e.target.scrollTop;
    scrollLeft.current = e.target.scrollLeft;
    setScrollX(scrollLeft.current);
  };

  useEffect(() => {
    if (activeCanvas) {
      activeCanvas.on("selection:created", handleSelectObject);
      activeCanvas.on("selection:updated", handleSelectObject);
      activeCanvas.on("selection:cleared", handleSelectObject);
      activeCanvas.on(
        "animation-duration:updated",
        handleUpdateArtboardSelection
      );
      activeCanvas.on("child:added", handleSelectObject);
    }
    return () => {
      if (activeCanvas) {
        activeCanvas.off("selection:created", handleSelectObject);
        activeCanvas.off("selection:updated", handleSelectObject);
        activeCanvas.off("selection:cleared", handleSelectObject);
        activeCanvas.off("child:added", handleSelectObject);
        activeCanvas.off(
          "animation-duration:updated",
          handleUpdateArtboardSelection
        );
      }
    };
  }, [activeCanvas, handleSelectObject]);
  useEffect(() => {
    if (activeCanvas) {
      activeCanvas.on("child:added", handleUpdateLayers);
      activeCanvas.on("cancel-animation", handleStopAnimationTemp);
      activeCanvas.on("child:removed", handleUpdateLayers);
      activeCanvas.on("undo", handleUpdateLayers);
      activeCanvas.on("redo", handleUpdateLayers);
      activeCanvas.on("play-temp-animation", handlePlayTimelineTemp);
    }
    return () => {
      if (activeCanvas) {
        activeCanvas.off("child:added", handleUpdateLayers);
        activeCanvas.off("cancel-animation", handleStopAnimationTemp);

        activeCanvas.off("child:removed", handleUpdateLayers);
        activeCanvas.off("undo", handleUpdateLayers);
        activeCanvas.off("redo", handleUpdateLayers);
        activeCanvas.off("play-temp-animation", handlePlayTimelineTemp);
      }
    };
  }, [selectedArtboard, activeCanvas]);

  const handleStopAnimationTemp = async () => {
    await handleStopAnimation();
    // handlePauseAnimation();
  };

  useEffect(() => {
    if (selectedArtboard) {
      setupAnimations(selectedArtboard, activeCanvas);
    }
  }, [selectedArtboard]);

  const setupAnimations = (artboard, activeCanvas) => {
    let animationTimeline = initializeAnimation(artboard, activeCanvas);
    animationTimelineRef.current = animationTimeline;
    animatingFrameRef.current = artboard;
  };

  function onEnterAnimationState() {
    if (animatingFrameRef.current)
      animatingFrameRef.current._animationStateReset = false;

    // activeCanvas.off('mouse:down:before', cancelAnimationState)
    // activeCanvas.on('mouse:down:before', cancelAnimationState)
    // activeCanvas.fire('animating:frame', {target: activeFrame})
  }

  const cancelAnimationState = React.useCallback(async () => {
    if (
      !animatingFrameRef.current ||
      animatingFrameRef.current._animationStateReset
    ) {
      return;
    }

    if (
      animationTimelineRef.current?._playState &&
      animationTimelineRef.current._playState !== "stopped"
    ) {
      await handleStopAnimation();
    }
    resetFrameState(true);
    activeCanvas.renderAll();
    activeCanvas.off("mouse:down:before", cancelAnimationState);
  }, [activeCanvas]);

  function resetFrameState(updateContext) {
    (animatingFrameRef.current?.children || []).forEach((child) => {
      if (child.actor) {
        child.actor.resetObjectState(child);
        if (updateContext) {
          child.actor.setContext(child);
        }
      }
    });

    if (animatingFrameRef.current) {
      animatingFrameRef.current._animationStateReset = true;
    }
  }

  function handleSelectObject() {
    setActiveObject(this.getActiveObject());
  }

  function handleUpdateLayers() {
    setSelectedArtboard(activeCanvas.getObjects("artboard")[0]);
    let list = activeCanvas.getObjects("artboard")[0].getChildren();
    list.id = Math.round(Math.random() * 1000);
    setLayers(list);
  }

  function handleUpdateArtboardSelection() {
    setSelectedArtboard(activeCanvas.getObjects("artboard")[0]);
    setAnimationDurationInMS(
      activeCanvas.getObjects("artboard")[0].animationDuration
    );
  }

  useEffect(() => {
    if (activeCanvas) {
      activeCanvas.on("page:loaded", handleUpdateArtboardSelection);
    }
    return () => {
      if (activeCanvas) {
        activeCanvas.off("page:loaded", handleUpdateArtboardSelection);
      }
    };
  }, [activeCanvas, handleUpdateArtboardSelection]);
  useEffect(() => {
    if (selectedArtboard) {
      setAnimationDurationInMS(selectedArtboard.animationDuration);
    }
  }, [selectedArtboard]);

  useEffect(() => {
    if (activeCanvas)
      setSelectedArtboard(activeCanvas.getObjects("artboard")[0]);
  }, [activeCanvas]);

  const setActorActualContext = (rekapi) => {
    let activeObjs =
      activeCanvas._activeObject?.type === "activeSelection" &&
      activeCanvas._activeObject._objects.slice();
    activeObjs && activeCanvas._discardActiveObject();

    rekapi.getAllActors().forEach((actor) => {
      if (actor.contextNeeded) {
        actor.setContext(
          actor.context.isDummy
            ? {}
            : actor.context.toObject(propertiesToInclude)
        );
      }
    });

    activeObjs &&
      activeCanvas._setActiveObject(
        new fabric.ActiveSelection(activeObjs, { canvas: activeCanvas })
      );
  };
  const handlePlayTimelineTemp = async ({ time, limit }) => {
    if (playingAnimationRef.current) return;
    await handlePlayTimeline(time);
  };
  const handlePlayTimeline = async (timeInMs) => {
    if (activeCanvas.getActiveObject()) activeCanvas._discardActiveObject();

    onEnterAnimationState();
    //it should happen only once because we need to record each childs original state
    if (
      !animationTimelineRef.current ||
      !animationTimelineRef.current.isPaused()
    ) {
      setupAnimations(selectedArtboard, activeCanvas);
    }

    setActorActualContext(animationTimelineRef.current);

    setPlayingAnimation(true);
    playingAnimationRef.current = true;
    let FPS = 30;

    let playFromInMS = timeInMs || seekbarTimeInMs;
    animationFrame.current = playFromInMS * (FPS / 1000);

    if (seekbarTimeInMs >= selectedArtboard.animationDuration) {
      animationFrame.current = 0;
      setSeekbarTimeInMs(0);
      playFromInMS = 0;
    }

    handleSeekAllMedia(selectedArtboard, playFromInMS);
    let frameElements = animatingFrameRef.current.children.filter(
      (obj) => !obj.manuallyHidden
    );

    let audioVideoElements = flattenGroupedObjects(
      { _objects: frameElements },
      [],
      "_objects"
    ).filter((o) => o.type === "video" || o.type === "audio");

    let fpsMultiplier = 1000 / FPS;

    animationPlayingInterval.current = setIntervalAsync(async () => {
      let currentTimeInMS = animationFrame.current * fpsMultiplier;
      setSeekbarTimeInMs(currentTimeInMS);
      /*if (cancelPlayingAnimation.current) {
                await handlePauseAnimation();
                cancelPlayingAnimation.current = false;
                return;
            }*/
      frameElements.forEach(
        (el) => (el.visible = shouldPlayMedia(el, currentTimeInMS))
      );
      audioVideoElements.forEach((child) => {
        if (child.visible) {
          if (typeof child.play === "function") {
            child.play();
          } else if (typeof child.playVideo === "function") {
            child.playVideo();
          }
        } else {
          if (typeof child.pause === "function") {
            child.pause();
          } else if (typeof child.pauseVideo === "function") {
            child.pauseVideo();
          }
        }
      });
      activeCanvas.requestRenderAll();
      if (currentTimeInMS > selectedArtboard.animationDuration) {
        await handlePauseAnimation();
      } else {
        animationFrame.current++;
      }
    }, fpsMultiplier);
    activeCanvas.animationTimeline.playFrom(playFromInMS, 1);
  };

  const handlePauseAnimation = async () => {
    if (animationPlayingInterval.current)
      clearIntervalAsync(animationPlayingInterval.current);

    activeCanvas.animationTimeline.pause();
    handlePauseAllMedia(animatingFrameRef.current);
    setPlayingAnimation(false);
    playingAnimationRef.current = false;
  };

  const handleStopAnimation = async () => {
    if (animationPlayingInterval.current)
      clearIntervalAsync(animationPlayingInterval.current);

    activeCanvas.animationTimeline.stop();

    await handleRewindAllMedia(animatingFrameRef.current);
    activeCanvas.requestRenderAll();

    animationFrame.current = 0;
    setSeekbarTimeInMs(0);
    setPlayingAnimation(false);
    playingAnimationRef.current = false;
  };

  const togglePlayPause = () => {
    if (playingAnimationRef.current) {
      handlePauseAnimation();
    } else {
      handlePlayTimeline();
    }
  };
  const handleSeekbarUpdate = async (time) => {
    await handlePauseAnimation();

    onEnterAnimationState();

    setActorActualContext(animationTimelineRef.current);
    activeCanvas.animating = true;

    setSeekbarTimeInMs(time);
    await handleSeekAllMedia(selectedArtboard, time);
    activeCanvas.animationTimeline.update(time);
    activeCanvas.requestRenderAll();
  };

  const handleSeekAllMedia = (artboard, currentTimeInMS) => {
    return new Promise((resolve) => {
      let currentTimeInSeconds = currentTimeInMS / 1000;
      Promise.allSettled(
        artboard
          .getChildren()
          .map((child) => {
            let seekTime =
              currentTimeInSeconds - child.animationStartTime + child.startTime;
            child.visible = shouldPlayMedia(child, currentTimeInMS);
            if (typeof child.seek === "function") {
              return child.seek(seekTime * child.speed);
            } else if (typeof child.seekVideo === "function") {
              return child.seekVideo(seekTime * child.speed);
            }
            return false;
          })
          .filter((promise) => !!promise)
      ).then(() => {
        resolve();
      });
      activeCanvas.requestRenderAll();
    });
  };

  const handleResetSeekAllMedia = (artboard, currentTimeInMS) => {
    return new Promise((resolve) => {
      Promise.allSettled(
        artboard
          .getChildren()
          .map((child) => {
            child.visible = shouldPlayMedia(child, currentTimeInMS);
            if (typeof child.seek === "function") {
              return child.seek(child.startTime);
            } else if (typeof child.seekVideo === "function") {
              return child.seekVideo(child.startTime);
            }
            return false;
          })
          .filter((promise) => !!promise)
      ).then(() => {
        resolve();
      });
      activeCanvas.requestRenderAll();
    });
  };

  const handlePixelPerSecondChange = (ppx) => {
    setPixelsPerSecond(ppx);
  };

  const handleLayerUpdate = (object) => {
    updateTransitionsDuration(object, object.transitions);
    reCalculateTransitionTime(object);
    reInitKeyframes(object);
    if (selectedArtboard.durationType === "fixed") return;

    setAnimationDurationInMS(selectedArtboard.autoCalculateDuration());
  };

  const handleTimelineSize = (options) => {
    setTimelineSize(options);
    onUpdateTimelineUpdate(options.height + 104);
  };

  const handleOnDragStart = () => {
    setDragBorder(0.5);
  };

  // Handles the drag and drop logic to reorder the layers
  // bringing the layers on top to the front
  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newLayers = [...layers];
    const [removed] = newLayers.splice(result.source.index, 1);
    newLayers.splice(result.destination.index, 0, removed);
    setLayers(newLayers);
    setDragBorder(0);

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    if (sourceIndex > destinationIndex) {
      // Move the item forward (multiple steps)
      for (let i = 0; i < sourceIndex - destinationIndex; i++) {
        activeCanvas.bringForward(removed);
      }
    } else if (sourceIndex < destinationIndex) {
      // Move the item backward (multiple steps)
      for (let i = 0; i < destinationIndex - sourceIndex; i++) {
        activeCanvas.sendBackwards(removed);
      }
    }

    activeCanvas.requestRenderAll();
  };

  return (
    <div
      style={{
        zIndex: 99,
        padding: 20,
        paddingTop: 0,
        position: "absolute",
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: "rgb(15 23 42)",
        borderTop: "1px solid gray",
      }}
    >
      <TimelineResizer
        timelineSize={timelineSize}
        setTimelineSize={handleTimelineSize}
      />
      <div className="flex flex-col relative">
        <TimelineHeader
          onPixelPerSecondChange={handlePixelPerSecondChange}
          pixelsPerSecond={pixelsPerSecond}
          onSeekbarUpdate={handleSeekbarUpdate}
          onTogglePlay={togglePlayPause}
          currentTimeMs={seekbarTimeInMs}
          animationDurationMs={animationDurationInMS}
          playing={playingAnimation}
          scrollLeft={scrollLeft.current}
          onCancelAnimation={cancelAnimationState}
        />
        <div
          style={{ height: timelineSize.height }}
          ref={layersScrollRef}
          className="pl-[10px] w-full relative overflow-auto"
        >
          <div style={{ width: pixelsPerSecond * maxTimelineLengthInSeconds }}>
            <div
              style={{
                minHeight: "30vh",
                border: `${dragBorder}px dashed white`,
              }}
            >
              <DragDropContext
                onDragStart={handleOnDragStart}
                onDragEnd={handleOnDragEnd}
              >
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {layers.map((child, index) => {
                        return (
                          <Draggable
                            draggableId={`draggable-${index}`}
                            index={index}
                            key={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <TimelineLayer
                                  key={`${child._id}`}
                                  pixelsPerSecond={pixelsPerSecond}
                                  canvas={activeCanvas}
                                  activeObject={activeObject}
                                  layersScrollRef={layersScrollRef}
                                  onLayerUpdate={handleLayerUpdate}
                                  element={child}
                                  dragHandleProps={provided.dragHandleProps}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </div>
        {selectedArtboard?.durationType === "fixed" && (
          <div
            style={{
              left:
                (animationDurationInMS / 1000) * pixelsPerSecond -
                scrollLeft.current +
                10,
              background: `repeating-linear-gradient(-45deg, rgba(228, 228, 230, 0.2), rgba(228, 228, 230, 0.2) 1px, transparent 1px, transparent 6px)`,
            }}
            className="absolute bottom-[17px] right-0 top-[56px]  "
          ></div>
        )}
        <Seekbar
          left={(seekbarTimeInMs / 1000) * pixelsPerSecond - scrollLeft.current}
        />
      </div>
    </div>
  );
};
export default TimeLine;

const updateTransitionsDuration = (object, transitions) => {
  let duration = object.endTime - object.startTime;
  let startDuration = transitions[0].duration;
  let endDuration = transitions[2].duration;

  let middleDuration = duration - (startDuration + endDuration);
  if (middleDuration < 0) {
    let half = middleDuration / 2;
    startDuration += half;

    endDuration += half;

    middleDuration = 0;
  }
  if (startDuration < 0) startDuration = 0;

  if (endDuration < 0) endDuration = 0;

  transitions[0].duration = startDuration;
  transitions[1].duration = middleDuration;
  transitions[2].duration = endDuration;
};
