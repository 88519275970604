import React from 'react';
function AnimationThumbnail({isActive,animation,onClick,onMouseEnter,onMouseLeave}) {


    const handleTransitionChange = () => {
        if(onClick)
            onClick(animation)
    }
    return (
        <div className="w-full"   onClick={handleTransitionChange} >
            <div className={`group`} onMouseEnter={()=>onMouseEnter(animation)} onMouseLeave={onMouseLeave} >
                <div className={` flex items-center justify-center h-[100px] w-[100px] border-[2px]  border-solid rounded-[8px] ${!isActive?'border-[#e1e1e3] group-hover:border-[#e1e1e3]':'border-[#a3b1ff] bg-[#ebedff]'} cursor-pointer`}>
                    <AnimationIcon iconName={animation.iconName}/>
                </div>
            </div>
            <span className={`text-xs text-grayDark  ${isActive&&'text-[#5666f5]'}`}>{animation.title}</span>
        </div>
    )
}

function AnimationIcon({onClick,iconName,classes=''}) {
    // let href= `${monoIcons}#${iconName}`
    // if (workingEnv === 'build')
    //     href = window.location.origin+`/assets/designer/static/media/icons-mono.svg?v=${cacheBuster}#`+iconName


    /*return (
        <svg onClick={(e)=>{
            onClick&&onClick(e)
        }} role="img" className={`icon  ${classes}`}>
            <use xlinkHref={href}/>
        </svg>
    );*/
    return (
        <img src={`/images/animationPreviews/${iconName}`}/>
    )
}

export default AnimationThumbnail;



