import ObjectID from "bson-objectid";
import { fabric } from "fabric";
import { cloneDeep } from "lodash";
import {
  cacheMaskGroups,
  cloneObject,
  deepFindObjectByType,
  enlivenDynamicPatterns,
  getAllNestedObjects,
} from "../../utils/canvasUtils";
import { propertiesToInclude } from "../index";
import { getHeaderElementTypes, objectDefaults } from "../../utils/helper";

import { defaultStaticTransition } from "../../../Timeline/TimeLine";
import {
  END_TRANSITIONS,
  MIDDLE_TRANSITION_INDEX,
  reCalculateTransitionTime,
  START_TRANSITIONS,
  transitionPositions,
} from "../../../Animations/utils";

const props = [
  "_id",
  "title",
  "children",
  "isClipPath",
  "hidden",
  "sectionId",
  "mockupItem",
  "thumbnailArtboard",
  "animationDuration",
  "animationType",
  "FPS",
  "autoLayout",
  "isComponent",
  "isSample",
  "component",
  "durationType",
];

/**
 * Artboard class
 * @class fabric.Artboard
 * @extends fabric.Rect
 *
 */
fabric.Artboard = class extends fabric.Rect {
  centeredRotation = true;
  type = "artboard";
  objectType = "artboard";
  objectCaching = false;
  lockMovementX = true;
  animationType = "dynamic"; //fixed or dynamic
  lockMovementY = true;
  boundsStrokeColor = "#808080";
  activeBoundsStrokeColor = "#0000ff";
  boundsStrokeWidth = 1;
  hasControls = false;
  strokeWidth = 0;
  isClipPath = true;
  hidden = false;
  children = [];
  fill = null;
  stroke = null;
  absolutePositioned = true;
  hoverCursor = "select";
  isComponent = false;
  needsItsOwnCache = () => false;
  forceOrigins = true;
  MIN_ZOOM = 0.2;
  pageBorderColor = "#000";
  pageBorderWidth = 1;
  // Since Artboard inherits this from Rect, we need to set it to undefined here;
  toPen = undefined;
  durationType = "automatic";
  stateProperties = fabric.Object.prototype.stateProperties.concat(...props);
  cacheProperties = fabric.Object.prototype.cacheProperties.concat(...props);
  animationDuration = 15000;
  constructor(options) {
    super(options);

    if (options) this.setOptions(options);

    this.showArtboardBounds = options.showArtboardBounds || true;
    this._id = options._id || ObjectID().toHexString();
    this.title = options.title || "";
    this.children = options.children || [];
    this.hidden = options.hidden || false;
    this.originX = "left";
    this.originY = "top";

    this.on("added", this.prepareArtboard);
    this.on("removed", this.destroyArtboard);
  }

  /**
   * Title of the artboard shown as a text element on top
   * Dragging the title will drag the artboard along with it's children
   *
   * @type String
   * @default Artboard
   *
   */
  prepareArtboard() {
    if (!this.title)
      this.title = `Artboard ${this.canvas.getObjects("artboard").length}`;

    this.visible = !this.hidden;

    this._startEvents();
  }

  isAnimated() {
    return false;
  }
  autoCalculateDuration() {
    // console.log(this.animationDuration);
    if (this.getChildren().length === 0) {
      this.animationDuration = 2000;
    } else {
      this.animationDuration = -1;
    }

    this.getChildren().forEach((object) => {
      let newDuration =
        (object.animationStartTime + (object.endTime - object.startTime)) *
        1000;
      if (this.animationDuration < newDuration) {
        this.animationDuration = newDuration;
      }
    });
    this.canvas.fire("animation-duration:updated", { target: this });

    return this.animationDuration;
  }
  _startEvents() {
    this.artboardEvents();
  }

  getFillAsCSS(pixelRatio, debug) {
    if (!this.fill) return "";

    if (typeof this.fill === "string") {
      return `background-color: ${this.fill};`;
    } else if (typeof this.fill.toCSS === "function") {
      return this.fill.toCSS(this, pixelRatio, debug);
    }

    return "";
  }

  storeTransformRelationShip(parent = this, storeAt) {
    const bossTransform = parent.calcTransformMatrix();
    const invertedBossTransform = fabric.util.invertTransform(bossTransform);

    const desiredTransform = fabric.util.multiplyTransformMatrices(
      invertedBossTransform,
      parent.calcTransformMatrix()
    );

    if (!storeAt) {
      this.__transformRelationship = desiredTransform;

      this.forEachChild((child) => {
        const desiredTransform = fabric.util.multiplyTransformMatrices(
          invertedBossTransform,
          child.calcTransformMatrix()
        );

        child.__transformRelationship = desiredTransform;
      });
    } else {
      storeAt.__transformRelationship = desiredTransform;
    }

    return desiredTransform;
  }

  getChildTransformRelationShip(parent = this, child) {
    if (!child.__transformRelationship) {
      this.storeTransformRelationShip(parent);
    }

    const newTransform = fabric.util.multiplyTransformMatrices(
      parent.calcTransformMatrix(),
      child.__transformRelationship
    );

    return fabric.util.qrDecompose(newTransform);
  }

  async artboardEvents() {
    this.addInitialChildren(this.children);

    // TODO: might not be a good idea
    setTimeout(() => {
      if (this.canvas) {
        this.children.forEach((child, index) => {
          this.canvas.moveTo(child, this.getZIndex() + index + 1);
        });
      }
    }, 0);

    this.on("mouseup", ({ e }) => {
      const { canvas } = this;

      if (e.detail === 2 && e.button === 0) {
        if (this.mockupItem) {
          const mockupItem = canvas.getObjectById(this.mockupItem, "mockup");

          if (mockupItem) {
            canvas.setActiveObject(mockupItem);

            canvas.zoomToFit(mockupItem);
          }
        }
      }
    });

    delete this.__skipWarpRender;
    delete this.__skipResetMockup;

    this.on("resized", this.handleArtboardResizing);
    this.on("resizing", this.handleArtboardResizing);

    this.on("mousedown", () => {
      if (this.children.length === 0) {
        this.canvas.setActiveObject(this);
      }

      this.set({
        __lastLeft: this.left,
        __lastTop: this.top,
      });
    });

    this.on("deselected", () => {
      delete this.__isSelected;
    });

    this.on("selected", () => {
      this.__isSelected = true;

      this.storeTransformRelationShip(this);
    });

    const handleArtboardMoving = ({ eventSource, movement }) => {
      const movedX =
        eventSource === "keyboard"
          ? movement.x
          : Number(this.left - this.__lastLeft);
      const movedY =
        eventSource === "keyboard"
          ? movement.y
          : Number(this.top - this.__lastTop);

      if (typeof movedX !== "number" || typeof movedY !== "number") return;
      if (isNaN(movedX) || isNaN(movedY)) return;

      this.forEachChild((child) => {
        this.updateChildAnimations(child, movedX, movedY);

        child.left += movedX;
        child.top += movedY;

        child.setPositionByOrigin(
          new fabric.Point(child.left, child.top),
          child.originX,
          child.originY
        );

        child.setCoords();
      });

      if (eventSource !== "keyboard") {
        this.set({
          __lastLeft: this.left,
          __lastTop: this.top,
        });
      }

      this.setCoords();
    };

    this.on("moving", handleArtboardMoving);
    this.on("nudged", handleArtboardMoving);
  }

  isTransparent() {
    return (
      !this.fill ||
      this.opacity === 0 ||
      (typeof this.fill === "string" &&
        new fabric.Color(this.fill).getAlpha() === 0)
    );
  }

  /**
   * @private
   * @param {String} key
   * @param {*} value
   * @return {fabric.Circle} thisArg
   */
  /** @ts-ignore */
  _set(key, value) {
    this.callSuper("_set", key, value);

    if (key === "width" || key === "height") {
      if (this.children?.length > 0) {
        this.storeTransformRelationShip();
      }
    }

    return this;
  }

  updateChildAnimations(child, movedX, movedY) {
    if (child.actor) {
      if (movedX !== 0) {
        var leftKeyframes = child.actor.getPropertiesInTrack("left");
      }
      if (movedY !== 0) {
        var topKeyframes = child.actor.getPropertiesInTrack("top");
      }

      leftKeyframes?.forEach((keyframe) => {
        child.actor.modifyKeyframeProperty(
          keyframe.name,
          keyframe.millisecond,
          {
            value: keyframe.value + movedX,
          }
        );
      });

      topKeyframes?.forEach((keyframe) => {
        child.actor.modifyKeyframeProperty(
          keyframe.name,
          keyframe.millisecond,
          {
            value: keyframe.value + movedY,
          }
        );
      });

      if (leftKeyframes || topKeyframes) {
        child.animations = child.actor.exportTimeline({ withId: true });
      }
    } else if (child.animations) {
      if (child.animations.propertyTracks?.left) {
        child.animations.propertyTracks?.left.forEach((left) => {
          left.value += movedX;
        });
      }
      if (child.animations.propertyTracks?.top) {
        child.animations.propertyTracks?.top.forEach((top) => {
          top.value += movedY;
        });
      }
    }
  }

  rotateChildren(rotateOnly = false) {
    this.children.forEach((child) => {
      const { angle, translateX, translateY } =
        this.getChildTransformRelationShip(this, child);

      child.set({
        flipX: false,
        flipY: false,
      });

      if (!rotateOnly) {
        child.setPositionByOrigin(
          { x: translateX, y: translateY },
          "center",
          "center"
        );
      }

      child.set({ angle });
      child.setCoords();
    });
  }

  handleArtboardResizing({ e, transform: { original, corner } }) {
    const altKey = e?.altKey;

    const resizeX = this.width - original.width;
    const resizeY = this.height - original.height;

    if (corner === "tl") {
      this.forEachChild((child) => {
        if (child.pinToEdge) {
          if (child.pinToEdge.h === "Left") {
            this.updateChildAnimations(child, -resizeX, 0);

            child.left -= resizeX;
          }

          if (child.pinToEdge.v === "Top") {
            this.updateChildAnimations(child, 0, -resizeY);

            child.top -= resizeY;
          }

          if (child.pinToEdge.h === "Center") {
            this.updateChildAnimations(child, -resizeX / 2, 0);

            child.left -= resizeX / 2;
          }

          if (child.pinToEdge.v === "Center") {
            this.updateChildAnimations(child, 0, -resizeY / 2);

            child.top -= resizeY / 2;
          }

          if (child.pinToEdge.h === "Scale" || child.pinToEdge.v === "Scale") {
            if (!altKey) {
              var constraint = child.translateToOriginPoint(
                new fabric.Point(child.left, child.top),
                child.originX !== "center" ? "center" : "right",
                child.originY !== "center" ? "center" : "bottom"
              );
            }

            if (child.pinToEdge.h === "Scale") {
              const scaleX =
                this.getScaledWidth() / (original.width * original.scaleX);
              child.scaleX *= scaleX;

              if (child.uniformScaling) {
                child.scaleY *= scaleX;
              }
            }

            if (child.pinToEdge.v === "Scale") {
              const scaleY =
                this.getScaledHeight() / (original.height * original.scaleY);
              child.scaleY *= scaleY;

              if (child.uniformScaling) {
                child.scaleX *= scaleY;
              }
            }

            if (!altKey) {
              child.setPositionByOrigin(constraint, "right", "bottom");
            }
          }
        }

        child.setCoords();
      });
    }

    if (corner === "tr") {
      this.forEachChild((child) => {
        if (child.pinToEdge) {
          if (child.pinToEdge.h === "Right") {
            this.updateChildAnimations(child, resizeX, 0);

            child.left += resizeX;
          }

          if (child.pinToEdge.v === "Top") {
            this.updateChildAnimations(child, 0, -resizeY);

            child.top -= resizeY;
          }

          if (child.pinToEdge.h === "Center") {
            this.updateChildAnimations(child, resizeX / 2, 0);

            child.left += resizeX / 2;
          }

          if (child.pinToEdge.v === "Center") {
            this.updateChildAnimations(child, 0, -resizeY / 2);

            child.top -= resizeY / 2;
          }

          if (child.pinToEdge.h === "Scale" || child.pinToEdge.v === "Scale") {
            if (!altKey) {
              var constraint = child.translateToOriginPoint(
                new fabric.Point(child.left, child.top),
                child.originX !== "center" ? "center" : "left",
                child.originY !== "center" ? "center" : "bottom"
              );
            }

            if (child.pinToEdge.h === "Scale") {
              const scaleX =
                this.getScaledWidth() / (original.width * original.scaleX);
              child.scaleX *= scaleX;

              if (child.uniformScaling) {
                child.scaleY *= scaleX;
              }
            }

            if (child.pinToEdge.v === "Scale") {
              const scaleY =
                this.getScaledHeight() / (original.height * original.scaleY);
              child.scaleY *= scaleY;

              if (child.uniformScaling) {
                child.scaleX *= scaleY;
              }
            }

            if (!altKey) {
              child.setPositionByOrigin(constraint, "left", "bottom");
            }
          }
        }

        child.setCoords();
      });
    }

    if (corner === "br") {
      this.forEachChild((child) => {
        if (child.pinToEdge) {
          if (child.pinToEdge.h === "Right") {
            this.updateChildAnimations(child, resizeX, 0);

            child.left += resizeX;
          }

          if (child.pinToEdge.v === "Bottom") {
            this.updateChildAnimations(child, 0, resizeY);

            child.top += resizeY;
          }

          if (child.pinToEdge.h === "Center") {
            this.updateChildAnimations(child, resizeX / 2, 0);

            child.left += resizeX / 2;
          }

          if (child.pinToEdge.v === "Center") {
            this.updateChildAnimations(child, 0, resizeY / 2);

            child.top += resizeY / 2;
          }

          if (child.pinToEdge.h === "Scale" || child.pinToEdge.v === "Scale") {
            if (!altKey) {
              var constraint = child.translateToOriginPoint(
                new fabric.Point(child.left, child.top),
                child.originX !== "center" ? "center" : "left",
                child.originY !== "center" ? "center" : "top"
              );
            }

            if (child.pinToEdge.h === "Scale") {
              const scaleX =
                this.getScaledWidth() / (original.width * original.scaleX);
              child.scaleX *= scaleX;

              if (child.uniformScaling) {
                child.scaleY *= scaleX;
              }
            }

            if (child.pinToEdge.v === "Scale") {
              const scaleY =
                this.getScaledHeight() / (original.height * original.scaleY);
              child.scaleY *= scaleY;

              if (child.uniformScaling) {
                child.scaleX *= scaleY;
              }
            }

            if (!altKey) {
              child.setPositionByOrigin(constraint, "left", "top");
            }
          }
        }

        child.setCoords();
      });
    }

    if (corner === "bl") {
      this.forEachChild((child) => {
        if (child.pinToEdge) {
          if (child.pinToEdge.h === "Left") {
            this.updateChildAnimations(child, -resizeX, 0);

            child.left -= resizeX;
          }

          if (child.pinToEdge.v === "Bottom") {
            this.updateChildAnimations(child, 0, resizeY);

            child.top += resizeY;
          }

          if (child.pinToEdge.h === "Center") {
            this.updateChildAnimations(child, -resizeX / 2, 0);

            child.left -= resizeX / 2;
          }

          if (child.pinToEdge.v === "Center") {
            this.updateChildAnimations(child, 0, resizeY / 2);

            child.top += resizeY / 2;
          }

          if (child.pinToEdge.h === "Scale" || child.pinToEdge.v === "Scale") {
            if (!altKey) {
              var constraint = child.translateToOriginPoint(
                new fabric.Point(child.left, child.top),
                child.originX !== "center" ? "center" : "right",
                child.originY !== "center" ? "center" : "top"
              );
            }

            if (child.pinToEdge.h === "Scale") {
              const scaleX =
                this.getScaledWidth() / (original.width * original.scaleX);
              child.scaleX *= scaleX;

              if (child.uniformScaling) {
                child.scaleY *= scaleX;
              }
            }

            if (child.pinToEdge.v === "Scale") {
              const scaleY =
                this.getScaledHeight() / (original.height * original.scaleY);
              child.scaleY *= scaleY;

              if (child.uniformScaling) {
                child.scaleX *= scaleY;
              }
            }

            if (!altKey) {
              child.setPositionByOrigin(constraint, "right", "top");
            }
          }
        }

        child.setCoords();
      });
    }

    // Middle controls
    if (corner === "mt") {
      this.forEachChild((child) => {
        if (child.pinToEdge) {
          if (child.pinToEdge.v === "Top") {
            this.updateChildAnimations(child, 0, -resizeY);

            child.top -= resizeY;
          }

          if (child.pinToEdge.v === "Center") {
            this.updateChildAnimations(child, 0, -resizeY / 2);

            child.top -= resizeY / 2;
          }

          if (child.pinToEdge.v === "Scale") {
            if (!altKey) {
              var constraint = child.translateToOriginPoint(
                new fabric.Point(child.left, child.top),
                child.originX !== "center" ? "center" : "center",
                child.originY !== "center" ? "center" : "bottom"
              );
            }

            const scaleY =
              this.getScaledHeight() / (original.height * original.scaleY);
            child.scaleY *= scaleY;

            if (!altKey) {
              child.setPositionByOrigin(constraint, "center", "bottom");
            }
          }
        }

        child.setCoords();
      });
    }

    if (corner === "mb") {
      this.forEachChild((child) => {
        if (child.pinToEdge) {
          if (child.pinToEdge.v === "Bottom") {
            this.updateChildAnimations(child, 0, resizeY);

            child.top += resizeY;
          }

          if (child.pinToEdge.v === "Center") {
            this.updateChildAnimations(child, 0, resizeY / 2);

            child.top += resizeY / 2;
          }

          if (child.pinToEdge.v === "Scale") {
            if (!altKey) {
              var constraint = child.translateToOriginPoint(
                new fabric.Point(child.left, child.top),
                child.originX !== "center" ? "center" : "center",
                child.originY !== "center" ? "center" : "top"
              );
            }

            const scaleY =
              this.getScaledHeight() / (original.height * original.scaleY);
            child.scaleY *= scaleY;

            if (!altKey) {
              child.setPositionByOrigin(constraint, "center", "top");
            }
          }
        }

        child.setCoords();
      });
    }

    if (corner === "mr") {
      this.forEachChild((child) => {
        if (child.pinToEdge) {
          if (child.pinToEdge.h === "Right") {
            this.updateChildAnimations(child, resizeX, 0);

            child.left += resizeX;
          }

          if (child.pinToEdge.h === "Center") {
            this.updateChildAnimations(child, resizeX / 2, 0);

            child.left += resizeX / 2;
          }

          if (child.pinToEdge.h === "Scale") {
            if (!altKey) {
              var constraint = child.translateToOriginPoint(
                new fabric.Point(child.left, child.top),
                child.originX !== "center" ? "center" : "left",
                child.originY !== "center" ? "center" : "center"
              );
            }

            const scaleX =
              this.getScaledWidth() / (original.width * original.scaleX);
            child.scaleX *= scaleX;

            if (!altKey) {
              child.setPositionByOrigin(constraint, "left", "center");
            }
          }
        }

        child.setCoords();
      });
    }

    if (corner === "ml") {
      this.forEachChild((child) => {
        if (child.pinToEdge) {
          if (child.pinToEdge.h === "Left") {
            this.updateChildAnimations(child, -resizeX, 0);

            child.left -= resizeX;
          }

          if (child.pinToEdge.h === "Center") {
            this.updateChildAnimations(child, -resizeX / 2, 0);

            child.left -= resizeX / 2;
          }

          if (child.pinToEdge.h === "Scale") {
            if (!altKey) {
              var constraint = child.translateToOriginPoint(
                new fabric.Point(child.left, child.top),
                child.originX !== "center" ? "center" : "right",
                child.originY !== "center" ? "center" : "center"
              );
            }

            const scaleX =
              this.getScaledWidth() / (original.width * original.scaleX);
            child.scaleX *= scaleX;

            if (!altKey) {
              child.setPositionByOrigin(constraint, "right", "center");
            }
          }
        }

        child.setCoords();
      });
    }

    original.width = this.width;
    original.height = this.height;
  }

  setChildrenCoords() {
    this.forEachChild((child) => {
      child.setCoords();
    });
  }

  destroyArtboard() {
    const __renderOnAddRemove = this.canvas.renderOnAddRemove;
    this.canvas.renderOnAddRemove = false;
    this.forEachChild((child) => {
      this.canvas.remove(child);
    });
    this.canvas.renderOnAddRemove = __renderOnAddRemove;
    this.canvas.requestRenderAll();
  }

  _addChild(child) {
    if (child.__parentArtboard) {
      child.__parentArtboard.removeChild(child, false);
    }

    if (this.children.find((ch) => ch._id === child._id)) return;

    this.children.push(child);
    this.children[child._id] = child;
  }

  checkContainedWithin(object) {
    return object.isContainedWithinObject(this, true, true);
  }

  /**
   * Adds a child to the artboard
   * @param {fabric.Object} child
   * @return {fabric.Object} child
   *
   */
  addChild(child, events) {
    this._addChild(child);
    this._onChildAdded(child, events);

    return child;
  }

  /**
   * Adds a child to the artboard
   * @param [{fabric.Object}] children
   * @return {[fabric.Object]} children
   *
   */
  addInitialChildren(children) {
    // see if there are any children on init
    // if so, activate them.
    children.forEach((child) => {
      if (this.__resetChildrenPosition) {
        child.__resetChildrenPosition = true;
      }

      // Helps with migration of old table cell data
      if (child.cellIndices && typeof child.cellIndices[0] === "number") {
        child.cellIndices = [child.cellIndices];
      }

      this.addChild(child);
    });

    // TODO: this is not reliable at all
    setTimeout(() => {
      if (this.canvas) {
        this.canvas.fire("artboard:children:added", { target: this, children });
        delete this.__resetChildrenPosition;
      }
    }, 0);
  }

  _removeChild(child) {
    if (!child) return;

    this.children = this.children.filter((ch) => ch?._id !== child._id);
    delete this.children[child._id];
  }

  /**
   * removes a child to the artboard
   * @param {fabric.Object} child
   * @return [fabric.Object|Boolean] children or false if no child removed
   *
   */
  removeChild(child, events) {
    this._removeChild(child);
    this._onChildRemoved(child, events);

    return child;
  }

  insertAt(child, index, nonSplicing) {
    if (this.children.find((ch) => ch._id === child._id)) return;

    if (nonSplicing) {
      this.children[index] = child;
    } else {
      this.children.splice(index, 0, child);
    }

    this._onChildAdded(child);

    child.moveTo(this.getZIndex() + 1 + index);

    return this;
  }

  deepGetAllChildren(children = this.children, objects = []) {
    children.forEach((child) => {
      objects.push(child);

      if (child.children) {
        this.deepGetAllChildren(child.children, objects);
      }
    });

    return objects;
  }

  getChildren(type) {
    if (typeof type === "undefined") {
      return this.children
        .slice()
        .filter((ch) => !ch?.excludeFromLayers && !ch?.excludeFromExport);
    }

    return this.children.filter(function (ch) {
      return (
        !ch?.excludeFromLayers && !ch?.excludeFromExport && ch?.type === type
      );
    });
  }

  forEachChild(callback, context) {
    const objects = this.deepGetAllChildren();

    for (let i = 0, len = objects.length; i < len; i++) {
      callback.call(context, objects[i], i, objects);
    }

    return this;
  }

  complexity() {
    return this.children.length;
  }

  hide() {
    this.hidden = true;
    this.visible = false;

    this.forEachChild((child) => {
      child.visible = this.visible;
    });

    this.canvas.requestRenderAll();
  }

  show() {
    this.hidden = false;
    this.visible = true;

    this.forEachChild((child) => {
      child.visible = this.visible;
    });

    this.canvas.requestRenderAll();
  }

  lock() {
    this.evented = false;
    this.selectable = false;

    this.forEachChild((child) => {
      if (child.evented) {
        child.evented = false;
        child.selectable = false;
        child.lockedByUser = true;
      }
    });

    this.canvas.requestRenderAll();
  }

  unlock() {
    this.evented = true;
    this.selectable = true;

    this.forEachChild((child) => {
      if (child.lockedByUser) {
        child.evented = true;
        child.selectable = true;

        delete child.lockedByUser;
      }
    });

    this.canvas.requestRenderAll();
  }

  /**
   * @private
   *
   */
  async _onChildAdded(child, events = true) {
    if (!this.canvas) return;

    child.startTime = child.startTime || 0;
    child.endTime = child.endTime || Math.round(this.animationDuration / 1000);
    child.animationStartTime = child.animationStartTime || 0;
    child.objectTransitionDuration = child.endTime - child.startTime;

    if (!child.transitions) {
      let t = {
        0: { ...defaultStaticTransition },
        1: { ...defaultStaticTransition },
        2: { ...defaultStaticTransition },
      };
      child.transitions = [0, 1, 2].map((index) => {
        return {
          ...t[index],
          transitionPosition: transitionPositions[index],
          duration:
            index === MIDDLE_TRANSITION_INDEX
              ? child.objectTransitionDuration - 2
              : 1,
        };
      });
      reCalculateTransitionTime(child);
    }

    child.__parentArtboard = this;
    child.parentArtboard = this._id;
    child.clipArea = this;

    child.canvas = this.canvas;

    if (child.shadow) {
      if (child.hasFill && child.hasFill()) {
        child.shadow.affectStroke = false;
      } else {
        child.shadow.affectStroke = true;
      }
    }

    // If child isn't already on canvas
    if (!this._childExistsInCanvas(child)) {
      this.canvas.add(child);
      if (child.__resetChildrenPosition) {
        child.left += this.left;
        child.top += this.top;

        this.updateChildAnimations(child, this.left, this.top);

        delete child.__resetChildrenPosition;
      }
    }

    this._setChildClipArea(child);

    child.setCoords();

    if (events && !child.__skipAddChildEvent) {
      this.fire("child:added", { target: this, child, action: "childAdded" });
      this.canvas.fire("child:added", {
        target: this,
        child,
        action: "childAdded",
      });

      this.canvas.fire("artboard:child:added", {
        target: this,
        child,
        action: "childAdded",
      });
    }

    delete child.__skipAddChildEvent;

    if (child.getZIndex() <= this.getZIndex()) {
      child.moveTo(this.getZIndex() + 1);
    }

    this.canvas.renderOnAddRemove && this.canvas.requestRenderAll();
    if (this.durationType !== "fixed") {
      this.autoCalculateDuration();
    }
  }

  isEmpty() {
    return !this.children.find((c) => !this.isHeaderItem(c));
  }

  isHeaderItem(child) {
    return getHeaderElementTypes().includes(child.objectType);
  }

  getStepsSection() {
    let elements = this.children.filter((object) => {
      if (
        object.objectType === objectDefaults.steps.objectType ||
        object.objectType === objectDefaults.stepCheckIcon.objectType ||
        object.objectType === objectDefaults.section.steps.objectType ||
        object.objectType === objectDefaults.stepAddIcon.objectType
      ) {
        return true;
      }
      return false;
    });
    return elements;
  }

  getToolsSection() {
    let tools = this.children.filter(
      (c) => c.objectType === objectDefaults.tool.objectType
    );
    let plusIconIds = tools.map((i) => i.nextPlusIcon).filter((id) => !!id);
    let objects = this.children.filter((object) => {
      if (
        object.objectType === objectDefaults.tool.objectType ||
        (object.objectType === objectDefaults.plus.objectType &&
          plusIconIds.includes(object._id)) ||
        object.objectType === objectDefaults.section.tool.objectType
      ) {
        return true;
      }
      return false;
    });
    return objects;
  }

  /**
   * @private
   *
   */
  _setChildClipArea(child) {
    const artboard = this;

    child.render = async function (ctx) {
      ctx.save();
      // position the artboard children clip mask area
      if (child.clipArea && artboard.isClipPath) {
        if (child.clipArea.group && child.clipArea.__transformRelationship) {
          const { translateX, translateY } =
            artboard.getChildTransformRelationShip(
              child.clipArea.group,
              artboard
            );

          const squarePath = new Path2D();
          squarePath.moveTo(
            translateX + child.clipArea.aCoords.tl.x,
            translateY + child.clipArea.aCoords.tl.y
          );
          squarePath.lineTo(
            translateX + child.clipArea.aCoords.tr.x,
            translateY + child.clipArea.aCoords.tr.y
          );
          squarePath.lineTo(
            translateX + child.clipArea.aCoords.br.x,
            translateY + child.clipArea.aCoords.br.y
          );
          squarePath.lineTo(
            translateX + child.clipArea.aCoords.bl.x,
            translateY + child.clipArea.aCoords.bl.y
          );
          squarePath.closePath();
          ctx.clip(squarePath);
        } else {
          const squarePath = new Path2D();
          squarePath.moveTo(
            child.clipArea.aCoords.tl.x,
            child.clipArea.aCoords.tl.y
          );
          squarePath.lineTo(
            child.clipArea.aCoords.tr.x,
            child.clipArea.aCoords.tr.y
          );
          squarePath.lineTo(
            child.clipArea.aCoords.br.x,
            child.clipArea.aCoords.br.y
          );
          squarePath.lineTo(
            child.clipArea.aCoords.bl.x,
            child.clipArea.aCoords.bl.y
          );
          squarePath.closePath();
          ctx.clip(squarePath);
        }
      }

      ctx.globalAlpha = artboard.opacity;
      if (child.type === "mockup") {
        child._render(ctx);
      } else if (child.type !== "blendObjects") {
        this._transformDone = true;
        this.callSuper("render", ctx);
        this._transformDone = false;
      }

      if (child.type === "group" || child.type === "blendObjects") {
        child._render(ctx);
      }

      ctx.restore();
    };
  }

  /**
   * @private
   *
   */
  _onChildRemoved(child, events = true) {
    delete child.clipArea;
    delete child.__parentArtboard;
    delete child.parentArtboard;

    const objectType = child.type.charAt(0).toUpperCase() + child.type.slice(1);
    if (fabric[objectType]) child.render = fabric[objectType].prototype.render;

    if (events) {
      this.fire("child:removed", {
        target: this,
        child,
        action: "childRemoved",
      });

      this.canvas.fire("child:removed", {
        target: this,
        child,
        action: "childRemoved",
      });
      this.canvas.fire("artboard:child:removed", {
        target: this,
        child,
        action: "childRemoved",
      });
    }
  }

  /**
   * @private
   *
   */
  _childExistsInCanvas(child) {
    return this.canvas.getObjectById(child._id, child.type);
  }

  isMultiSelect = () => {
    return (
      this.canvas &&
      typeof this.canvas.getActiveObject === "function" &&
      this.canvas.getActiveObject() &&
      this.canvas.getActiveObject().type === "activeSelection"
    );
  };

  /**
   * @description Converts an object into a HTMLCanvas element
   *
   * @param {Object} options Options object
   * @param {Number} [options.multiplier] Multiplier to scale by
   * @param {Number} [options.left] Cropping left offset. Introduced in v1.2.14
   * @param {Number} [options.top] Cropping top offset. Introduced in v1.2.14
   * @param {Number} [options.width] Cropping width. Introduced in v1.2.14
   * @param {Number} [options.height] Cropping height. Introduced in v1.2.14
   * @param {Boolean} [options.enableRetinaScaling] Enable retina scaling for clone image. Introduce in 1.6.4
   * @param {Boolean} [options.withoutTransform] Remove current object transform ( no scale , no angle, no flip, no skew ). Introduced in 2.3.4
   * @param {Boolean} [options.withoutShadow] Remove current object shadow. Introduced in 2.4.2
   *
   * @return {HTMLCanvasElement} Returns DOM element <canvas> with the fabric.Object
   */
  async toCanvasElement({ enableRetinaScaling, multiplier = 1 }, element) {
    try {
      const cloned = await this.cloneAsync(propertiesToInclude);
      const clonedChildren = cloned.children.filter(
        ({ type, width, height, left, top }) => {
          return (
            !(isNaN(width) || width === 0) &&
            !(isNaN(height) || height === 0) &&
            !isNaN(left) &&
            !isNaN(top) &&
            type !== "audio"
          );
        }
      );

      const exportCanvas = new fabric.StaticCanvas(
        element || document.createElement("canvas"),
        {
          enableRetinaScaling: !!enableRetinaScaling,
          objectCaching: false,
          skipOffscreen: false,
          width: cloned.width * multiplier,
          height: cloned.height * multiplier,
        }
      );

      const vp = exportCanvas.viewportTransform;
      const zoom = exportCanvas.getZoom();
      const newZoom = zoom * multiplier;
      const translateX = vp[4] * multiplier;
      const translateY = vp[5] * multiplier;
      const newVp = [newZoom, 0, 0, newZoom, translateX, translateY];
      exportCanvas.viewportTransform = newVp;

      const children = this.children.filter(
        ({ type, width, height, left, top }) => {
          return (
            !(isNaN(width) || width === 0) &&
            !(isNaN(height) || height === 0) &&
            !isNaN(left) &&
            !isNaN(top) &&
            type !== "audio"
          );
        }
      );

      if (cloned.fill?.type === "pattern") {
        await enlivenDynamicPatterns(cloned, "fill");
      }
      if (cloned.stroke?.type === "pattern") {
        await enlivenDynamicPatterns(cloned, "stroke");
      }

      clonedChildren.forEach((child) => {
        child.dirty = true;
        child.evented = false;
        child.left -= this.left + (this.group?.left || 0);
        child.top -= this.top + (this.group?.top || 0);
      });

      await this.afterClonedChildren(children, clonedChildren);

      // The rectangle will take the place of the artboard
      // Just to show the background color etc.
      exportCanvas.add(
        new fabric.Rect({
          evented: false,
          originX: cloned.originX,
          originY: cloned.originY,
          width: cloned.width,
          height: cloned.height,
          left: 0,
          top: 0,
          fill: cloned.fill,
        })
      );

      exportCanvas.add(...clonedChildren);

      exportCanvas.forEachObject((child) => {
        child.dirty = true;
      });

      exportCanvas.renderAll();

      return exportCanvas;
    } catch (error) {
      console.log(error);
    }
  }

  async toGroup() {
    const _that = this;

    const children = this.children.filter(
      ({ type }) => type !== "audio" && type !== "youTube"
    );

    return new Promise((resolve) => {
      this.clone(
        async (cloned) => {
          delete cloned.mockupItem;
          delete cloned.sectionId;
          delete cloned.smartObject;

          const clonedChildren = cloned.children.filter(
            ({ type }) => type !== "audio" && type !== "youTube"
          );

          if (cloned.fill && cloned.fill.type === "pattern") {
            await enlivenDynamicPatterns(cloned, "fill");
          }
          if (cloned.stroke && cloned.stroke.type === "pattern") {
            await enlivenDynamicPatterns(cloned, "stroke");
          }

          await this.afterClonedChildren(children, clonedChildren);

          const group = new fabric.Group([cloned], {
            canvas: this.canvas,
            needsItsOwnCache: () => true,
          });
          group.add(...clonedChildren);

          this.storeTransformRelationShip(group, cloned);

          group._objects.forEach(function (object) {
            if (object.type === "artboard") return;

            fabric.util.removeTransformFromObject(
              object,
              group.calcTransformMatrix()
            );
          });

          group.render = function (ctx) {
            const artboard = group.getObjects("artboard")[0];
            if (!artboard) return;

            ctx.save();
            this._transformDone = true;
            const coords = artboard.aCoords;

            const { translateX, translateY } =
              _that.getChildTransformRelationShip(group, cloned);

            const squarePath = new Path2D();
            squarePath.moveTo(
              translateX + coords.tl.x,
              translateY + coords.tl.y
            );
            squarePath.lineTo(
              translateX + coords.tr.x,
              translateY + coords.tr.y
            );
            squarePath.lineTo(
              translateX + coords.br.x,
              translateY + coords.br.y
            );
            squarePath.lineTo(
              translateX + coords.bl.x,
              translateY + coords.bl.y
            );
            squarePath.closePath();
            ctx.clip(squarePath);

            this.callSuper("render", ctx);
            this._transformDone = false;
            ctx.restore();
          };

          resolve(group);
        },
        [...propertiesToInclude]
      );
    });
  }

  addChildToCenter(child) {
    child.setPositionByOrigin(
      this.getPointByOrigin("center", "center"),
      "center",
      "center"
    );
    child.setCoords();
    this.addChild(child);
  }

  async afterClonedChildren(children, clonedChildren) {
    const allTargetNestedObjects = getAllNestedObjects({ children });

    await Promise.all(
      clonedChildren.map(async (child, i) => {
        child.needsItsOwnCache = () => !child.transformEffect;

        if (child.isVideo) {
          child.filters = [];

          child.setElement(children[i].getElement(), {
            scaleX: children[i].scaleX,
            scaleY: children[i].scaleY,
            width: children[i].width,
            height: children[i].height,
            layout: children[i].layout,
            cropX: children[i].cropX,
            cropY: children[i].cropY,
            warpCoords: children[i].warpCoords,
            perspectiveCoords: children[i].perspectiveCoords,
            cornerRadius: children[i].cornerRadius,
            ovalArc: children[i].ovalArc,
          });
          child.updatePhotoCanvas();

          if (children[i].filters) {
            child.filters = cloneDeep(children[i].filters);
          }
        }

        if (child.group) cacheMaskGroups(child.group);

        if (child.type === "group") {
          const allNestedObjects = getAllNestedObjects({
            children: clonedChildren,
          });

          await Promise.all(
            allNestedObjects.map(async (obj, j) => {
              if (obj.transformEffect) {
                child.needsItsOwnCache = () => false;
              }

              if (obj.isVideo) {
                const target = allTargetNestedObjects[j];
                const element =
                  typeof target?.getElement === "function" &&
                  target?.getElement();

                if (element) {
                  obj.filters = [];
                  obj.setElement(element, {
                    scaleX: target.scaleX,
                    scaleY: target.scaleY,
                    width: target.width,
                    height: target.height,
                    layout: target.layout,
                    cropX: target.cropX,
                    cropY: target.cropY,
                    warpCoords: target.warpCoords,
                    perspectiveCoords: target.perspectiveCoords,
                    cornerRadius: target.cornerRadius,
                    ovalArc: target.ovalArc,
                  });
                  obj.updatePhotoCanvas();

                  if (target.filters) {
                    obj.filters = cloneDeep(target.filters);
                  }
                }
              }

              if (obj.group) cacheMaskGroups(obj.group);

              if (obj.fill?.type === "pattern") {
                await enlivenDynamicPatterns(obj, "fill");
              }
              if (obj.stroke?.type === "pattern") {
                await enlivenDynamicPatterns(obj, "stroke");
              }
            })
          );

          child.dirty = true;
        }

        if (child.fill && child.fill.type === "pattern") {
          await enlivenDynamicPatterns(child, "fill");
        }
        if (child.stroke && child.stroke.type === "pattern") {
          await enlivenDynamicPatterns(child, "stroke");
        }
      })
    );
  }

  /**
   * @private
   * @param {CanvasRenderingContext2D} ctx Context to render on
   *
   */
  /** @ts-ignore */
  _render(ctx) {
    if (!this.canvas) return;
    if (this.canvas?.skipOffscreen && !this.group && !this.isOnScreen()) return;

    const isGrouped = this.group && this.group.type === "group";

    if (this.showArtboardBounds && !isGrouped) {
      if (this.isComponent || this.isSample) {
        this._renderComponentBounds(ctx);
      } else {
        this._renderArtboardBounds(ctx);
      }
    }

    this._renderPaintInOrder(ctx);
  }
  positionChildToCenter(child) {
    let c = this.getCenterPoint();
    child.setPositionByOrigin(c, "center", "center");
    child.setCoords();
  }

  _renderArtboardBounds(ctx) {
    const zoom = this.canvas.getZoom();
    const w = this.width;
    const h = this.height;
    const x = -this.width / 2;
    const y = -this.height / 2;

    ctx.beginPath();
    ctx.strokeStyle =
      this === this.canvas._activeArtboard
        ? this.activeBoundsStrokeColor
        : this.boundsStrokeColor;
    ctx.lineWidth = this.boundsStrokeWidth / zoom;
    ctx.moveTo(x, y);
    ctx.lineTo(x + w, y);
    ctx.lineTo(x + w, y + h);
    ctx.lineTo(x, y + h);
    ctx.closePath();
    ctx.stroke();

    //render stroke around frame
    let strokeWidth = 0;
    ctx.beginPath();
    ctx.strokeStyle = this.pageBorderColor;
    ctx.lineWidth = this.pageBorderWidth;
    ctx.moveTo(x - strokeWidth, y - strokeWidth);
    ctx.lineTo(x + w + strokeWidth, y - strokeWidth);
    ctx.lineTo(x + w + strokeWidth, y + h + strokeWidth);
    ctx.lineTo(x - strokeWidth, y + h + strokeWidth);
    ctx.closePath();
    ctx.stroke();
    ctx.strokeStyle = this.boundsStrokeColor;
  }

  _renderArtboardTitle(ctx) {
    const x = -this.width / 2;
    const y = -this.height / 2;
    const w = this.width;
    const h = this.height;
    let canvasZoom = this.canvas.getZoom();
    const animated = this.__isAnimated;

    let zoom = canvasZoom;

    let leftPadding = 28 / zoom;

    if (zoom <= this.MIN_ZOOM) {
      zoom *= 2.25;
      leftPadding = 8 / zoom;
    }

    if (!animated && !this?.__isRecording) {
      leftPadding = 8 / zoom;
    }

    let textFillStyle = "#8F8F8F";

    if (this?.__isRecording) {
      textFillStyle = "#FF3C31";
    }

    const currentDir = ctx.canvas.getAttribute("dir");
    ctx.canvas.setAttribute("dir", "ltr");
    ctx.save();
    ctx.globalCompositeOperation = "source-over";

    ctx.globalAlpha = 1;

    // Find the best possible spot for the title in case of rotation
    let newY = y;
    let newX = x;
    let tWidth = w;
    const normalizedAngle = (this.angle + 360) % 360;
    if (normalizedAngle >= 45 && normalizedAngle < 135) {
      newY = x;
      newX = y;
      ctx.rotate((-90 * Math.PI) / 180);
      tWidth = h;
    } else if (normalizedAngle >= 135 && normalizedAngle < 225) {
      ctx.rotate((180 * Math.PI) / 180);
    } else if (normalizedAngle >= 225 && normalizedAngle < 315) {
      newY = x;
      newX = y;
      tWidth = h;
      ctx.rotate((90 * Math.PI) / 180);
    }

    const titleHeight = fabric.ARTBOARD_TITLE_HEIGHT;

    ctx.save();

    // Title background
    ctx.beginPath();
    ctx.fillStyle = "#E6E6E6";
    const r = 8;
    const radius = Math.max(r / zoom, 0);
    ctx.translate(newX, newY - (titleHeight + 8) / zoom);
    ctx.moveTo(r / zoom, 0);
    ctx.arcTo(tWidth, 0, tWidth, titleHeight / zoom, radius);
    ctx.arcTo(tWidth, titleHeight / zoom, 0, titleHeight / zoom, radius);
    ctx.arcTo(0, titleHeight / zoom, 0, 0, radius);
    ctx.arcTo(0, 0, tWidth, 0, radius);
    ctx.fill();

    // play icon
    if (
      !this?.__isRecording &&
      animated &&
      canvasZoom > this.MIN_ZOOM &&
      tWidth >= 30 / zoom
    ) {
      ctx.save();
      ctx.scale(1 / zoom, 1 / zoom);
      ctx.translate(12, 11);

      if (!this.__isAnimationPlaying) {
        ctx.beginPath();
        ctx.strokeStyle = "#8F8F8F";
        ctx.lineWidth = 0.95;
        const path = new Path2D(
          "M1 1.809v6.382a.5.5 0 0 0 .724.447l6.382-3.19a.5.5 0 0 0 0-.895L1.724 1.362A.5.5 0 0 0 1 1.809Z"
        );
        ctx.stroke(path);
      } else {
        ctx.beginPath();
        ctx.strokeStyle = "#8F8F8F";
        ctx.lineWidth = 0.75;
        ctx.rect(0, 0, 3, 9);
        ctx.stroke();

        ctx.beginPath();
        ctx.strokeStyle = "#8F8F8F";
        ctx.lineWidth = 0.75;
        ctx.rect(6, 0, 3, 9);
        ctx.stroke();
      }
      ctx.restore();
    }

    // Recording icon
    if (
      this?.__isRecording &&
      canvasZoom > this.MIN_ZOOM &&
      tWidth >= 30 / zoom
    ) {
      ctx.save();
      ctx.translate(16 / zoom, 16 / zoom);

      ctx.beginPath();
      ctx.fillStyle = "#FF3C31";
      ctx.arc(0, 0, 4 / zoom, 0, Math.PI * 2, false);
      ctx.fill();

      ctx.beginPath();
      ctx.lineWidth = 0.65 / zoom;
      ctx.strokeStyle = "#FF3C31";
      ctx.arc(0, 0, 6 / zoom, 0, Math.PI * 2, false);
      ctx.stroke();

      ctx.restore();
    }

    // Export icon
    if (
      !this?.__isRecording &&
      canvasZoom > this.MIN_ZOOM &&
      tWidth >= 80 / zoom
    ) {
      ctx.save();
      ctx.fillStyle = "#8F8F8F";
      ctx.translate(tWidth - 58 / zoom, 10 / zoom);
      ctx.scale(1 / zoom, 1 / zoom);
      const path = new Path2D(
        `M5.5 1a.5.5 0 0 1 1 0v5.793l1.646-1.647a.5.5 0 1 1 .708.708l-2.5
        2.5L6 8.707l-.354-.353-2.5-2.5a.5.5 0 1 1 .708-.708L5.5 6.793V1Zm-4
        8A1.5 1.5 0 0 0 3 10.5h6A1.5 1.5 0 0 0 10.5 9V7.5h1V9A2.5 2.5 0 0 1
        9 11.5H3A2.5 2.5 0 0 1 .5 9V7.5h1V9Z`
      );
      ctx.lineWidth = 1;
      ctx.fill(path);
      ctx.restore();

      ctx.save();
      ctx.fillStyle = textFillStyle;
      ctx.font = `500 ${11 / zoom}px "aktiv-grotesk", sans-serif`;
      const exportTextWidth = ctx.measureText("Export").width;
      ctx.fillText("Export", tWidth - exportTextWidth - 8 / zoom, 20 / zoom);

      ctx.restore();
    }

    ctx.restore();

    ctx.beginPath();
    // Artboard title
    ctx.font = `500 ${11 / zoom}px "aktiv-grotesk", sans-serif`;
    const titleWidth = ctx.measureText(this.title).width;
    let text = titleWidth + 80 / zoom >= tWidth ? "..." : this.title;
    if (tWidth < 30 / zoom + ctx.measureText("...").width) text = "";

    ctx.fillStyle = textFillStyle;
    let xx = leftPadding;
    let yy = 20 / zoom;
    ctx.fillText(text, newX + xx, newY - yy);
    ctx.restore();

    ctx.canvas.setAttribute("dir", currentDir);
  }

  _renderComponentBounds(ctx) {
    const zoom = this.canvas.getZoom();

    const padding = (this.padding / zoom) * 2;
    const w = this.width + padding / this.scaleX;
    const h = this.height + padding / this.scaleY;
    const x = -w / 2;
    const y = -h / 2;

    ctx.save();
    ctx.beginPath();
    if (!this.isSample) ctx.setLineDash([5 / zoom, 5 / zoom]);
    ctx.strokeStyle = this.boundsStrokeColor;
    ctx.lineWidth = this.boundsStrokeWidth / zoom;
    ctx.moveTo(x, y);
    ctx.lineTo(x + w, y);
    ctx.lineTo(x + w, y + h);
    ctx.lineTo(x, y + h);
    ctx.closePath();
    ctx.scale(1 / this.scaleX, 1 / this.scaleY);
    ctx.stroke();
    ctx.restore();
  }

  renderComponentTitle(ctx) {
    const zoom = this.canvas?.getZoom() || 1;

    const padding = (this.padding / zoom) * 2;
    const w = this.width + padding / this.scaleX;
    const h = this.height + padding / this.scaleY;
    const x = -w / 2;
    const y = -h / 2;

    // Render artboard title
    if (!this.canvas?.thumbnailCanvas) {
      const currentDir = ctx.canvas.getAttribute("dir");
      ctx.canvas.setAttribute("dir", "ltr");
      ctx.save();
      ctx.globalCompositeOperation = "source-over";
      ctx.scale(1 / this.scaleX, 1 / this.scaleY);
      ctx.globalAlpha = 1;
      ctx.font = `500 ${11 / zoom}px "aktiv-grotesk", sans-serif`;
      const titleWidth = ctx.measureText(this.title).width;
      const text = titleWidth >= this.width ? "..." : this.title;
      // Find the best possible spot for the title in case of rotation
      let newY = y * this.scaleX;
      let newX = x * this.scaleY;
      const normalizedAngle = (this.angle + 360) % 360;
      if (normalizedAngle >= 45 && normalizedAngle < 135) {
        newY = x;
        newX = y;
        ctx.rotate((-90 * Math.PI) / 180);
      } else if (normalizedAngle >= 135 && normalizedAngle < 225) {
        ctx.rotate((180 * Math.PI) / 180);
      } else if (normalizedAngle >= 225 && normalizedAngle < 315) {
        newY = x;
        newX = y;
        ctx.rotate((90 * Math.PI) / 180);
      }

      if (!this.autoLayout) {
        ctx.save();
        ctx.beginPath();
        ctx.fillStyle = this.borderColor;
        ctx.fillRule = "evenodd";
        ctx.clipRule = "evenodd";
        ctx.translate(newX, newY - 17 / zoom);
        ctx.scale(1 / zoom, 1 / zoom);
        const path = new Path2D(
          `M2.908 3.615 1.402 5.121a1 1 0 0 0 0 1.415l1.506 1.506 2.213-2.214-2.213-2.213Zm.707-.707
          2.213 2.213 2.214-2.213-1.506-1.506a1 1 0 0 0-1.415 0L3.615 2.908Zm2.213 3.628L3.615 8.749l1.506
          1.506a1 1 0 0 0 1.415 0L8.042 8.75 5.828 6.536Zm2.92 1.506L6.537 5.828l2.213-2.213 1.506 1.506a1
          1 0 0 1 0 1.415L8.75 8.042Zm0 1.414-1.505 1.506a2 2 0 0 1-2.829 0L2.908 9.456l-.707-.707L.695
          7.243a2 2 0 0 1 0-2.829l3.72-3.72a2 2 0 0 1 2.828 0l1.506 1.507.707.707
          1.506 1.506a2 2 0 0 1 0 2.829L9.456 8.749l-.707.707Z`
        );
        ctx.fill(path);
        ctx.restore();

        ctx.translate(15 / zoom, 0);
      }

      ctx.fillStyle = !this.autoLayout ? this.borderColor : "#828282";
      ctx.fillText(text, newX, newY - 7 / zoom);
      ctx.restore();
      ctx.canvas.setAttribute("dir", currentDir);
    }
  }

  drawGridLines() {
    if (!this.visible || !this.grid || !this.grid.visible) return;

    const ctx = this.canvas.getSelectionContext();
    if (ctx === null) return;

    const zoom = this.canvas.getZoom();
    const w = this.width;
    const h = this.height;
    let x = this.left;
    let y = this.top;

    if (this.group) {
      x += this.group.left;
      y += this.group.top;
    }

    ctx.save();
    ctx.transform(...this.canvas.viewportTransform);

    ctx.beginPath();

    const region = new Path2D();
    region.rect(x, y, w, h);

    const size = this.grid.size;

    ctx.lineWidth = 1 / zoom;
    ctx.strokeStyle = this.grid.color;

    if (this.grid.gridMode.value === "square") {
      ctx.translate(x, y);
      ctx.rotate((this.angle * Math.PI) / 180);

      for (let i = 0; i < w / size; i++) {
        ctx.moveTo(size * i, 0);
        ctx.lineTo(size * i, h);
      }

      for (let i = 0; i < h / size; i++) {
        ctx.moveTo(0, size * i);
        ctx.lineTo(w, size * i);
      }
    }

    if (this.grid.gridMode.value === "rectangle") {
      ctx.translate(x, y);
      ctx.rotate((this.angle * Math.PI) / 180);

      let gridWidth = this.grid.width;

      if (gridWidth > 0) {
        for (let i = 0; i <= w / gridWidth; i++) {
          ctx.moveTo(gridWidth * i, 0);
          ctx.lineTo(gridWidth * i, h);
        }
      }

      let gridHeight = this.grid.height;

      if (gridHeight > 0) {
        for (let i = 0; i <= h / gridHeight; i++) {
          ctx.moveTo(0, gridHeight * i);
          ctx.lineTo(w, gridHeight * i);
        }
      }
    }

    if (this.grid.gridMode.value === "percentage") {
      ctx.translate(x, y);
      ctx.rotate((this.angle * Math.PI) / 180);

      let rowCount = Number(this.grid.rowCount) ?? 0;
      let columnCount = Number(this.grid.columnCount) ?? 0;

      if (columnCount > 0) {
        let colSize = (w / 100) * columnCount;
        for (let i = 0; i <= w / colSize; i++) {
          ctx.moveTo(colSize * i, 0);
          ctx.lineTo(colSize * i, h);
        }
      }

      if (rowCount > 0) {
        let rowSize = (h / 100) * rowCount;
        for (let i = 0; i <= h / rowSize; i++) {
          ctx.moveTo(0, rowSize * i);
          ctx.lineTo(w, rowSize * i);
        }
      }
    }

    const tan30 = Math.tan(Math.PI / 6);
    const tan60 = Math.tan((Math.PI / 6) * 2);

    let remainder1 = w % size;
    let remainder2 = h % sideLength;
    const sideLength = tan30 * size;
    const adjustedContainerWidth = remainder1 ? w + size - remainder1 : w;
    const adjustedContainerHeight = remainder2
      ? h + sideLength - remainder2
      : h;
    const xw = x + adjustedContainerWidth;
    const yh = y + adjustedContainerHeight;

    if (this.grid.gridMode.value === "isometric") {
      ctx.translate(x, y);
      ctx.rotate((this.angle * Math.PI) / 180);
      ctx.translate(-x, -y);

      const evenVerticalGridPointCount = h % (sideLength * 2) > sideLength;

      let stepY, stepX, yOffset, xOffset;
      for (let i = 0, j = 0; i < adjustedContainerWidth; i += size, j++) {
        // Draw vertical lines first, offsetting the grid to left-0
        ctx.moveTo(x + i, y);
        ctx.lineTo(x + i, yh);

        stepY = (adjustedContainerWidth - i) * tan30;
        if (j % 2 === 0) {
          // Draw diagonal lines from top to right
          xOffset = Math.max(0, stepY - adjustedContainerHeight) * tan60;
          ctx.moveTo(x + i, y);
          ctx.lineTo(xw - xOffset, Math.min(yh, y + stepY));

          if (evenVerticalGridPointCount) {
            // Draw diagonal lines from bottom to right
            ctx.moveTo(x + i, yh);
            ctx.lineTo(xw - xOffset, Math.max(y, yh - stepY));
          }
        } else {
          if (!evenVerticalGridPointCount) {
            // Draw diagonal lines from bottom to right
            xOffset = Math.max(0, stepY - adjustedContainerHeight) * tan60;
            ctx.moveTo(x + i, yh);
            ctx.lineTo(xw - xOffset, Math.max(y, yh - stepY));
          }
        }
      }

      // Draw diagonal lines from left offset.
      for (
        let i = sideLength * 2, j = 2;
        i <= adjustedContainerHeight;
        i += sideLength * 2, j += 2
      ) {
        stepY = y + sideLength * j;
        // Shoot up and right
        stepX = sideLength * tan60 * j;
        yOffset = Math.max(0, x + stepX - xw) * tan30;
        ctx.moveTo(x, stepY);
        ctx.lineTo(Math.min(xw, x + stepX), y + yOffset);
        // Shoot down and right
        stepX = (yh - stepY) * tan60;
        yOffset = Math.max(0, x + stepX - xw) * tan30;
        ctx.moveTo(x, stepY);
        ctx.lineTo(Math.min(xw, x + stepX), yh - yOffset);
      }
      ctx.clip(region);
    }

    ctx.stroke();
    ctx.restore();
  }

  /**
   * Checks if point is inside the object
   * @param {fabric.Point} point Point to check against
   * @param {Object} [lines] object returned from @method _getImageLines
   * @param {Boolean} [absolute] use coordinates without viewportTransform
   * @param {Boolean} [calculate] use coordinates of current position instead of .oCoords
   * @return {Boolean} true if point is inside the object
   */

  containsPoint(point, lines, absolute, calculate) {
    var _coords = this._getCoords(absolute, calculate),
      _lines = lines || this._getImageLines(_coords),
      _xPoints = this._findCrossPoints(point, _lines);
    // if xPoints is odd then point is inside the object
    if (this.canvas._activeObject && this.canvas._activeObject.type === "frame")
      return true;

    return _xPoints !== 0 && _xPoints % 2 === 1;
  }

  componentContainsPoint(point, linesCloned) {
    const offset = 0;
    const theta = fabric.util.degreesToRadians(270 + this.angle);

    const normalizedAngle = (this.angle + 360) % 360;
    if (normalizedAngle >= 315 || normalizedAngle < 45) {
      linesCloned.topline.o.x += offset * Math.cos(theta);
      linesCloned.topline.o.y += offset * Math.sin(theta);
      linesCloned.topline.d.x += offset * Math.cos(theta);
      linesCloned.topline.d.y += offset * Math.sin(theta);
    } else if (normalizedAngle >= 45 && normalizedAngle < 135) {
      linesCloned.leftline.o.x += offset * Math.sin(theta);
      linesCloned.leftline.o.y -= offset * Math.cos(theta);
      linesCloned.leftline.d.x += offset * Math.sin(theta);
      linesCloned.leftline.d.y -= offset * Math.cos(theta);
    } else if (normalizedAngle >= 135 && normalizedAngle < 225) {
      linesCloned.bottomline.o.x -= offset * Math.cos(theta);
      linesCloned.bottomline.o.y -= offset * Math.sin(theta);
      linesCloned.bottomline.d.x -= offset * Math.cos(theta);
      linesCloned.bottomline.d.y -= offset * Math.sin(theta);
    } else {
      linesCloned.rightline.o.x -= offset * Math.sin(theta);
      linesCloned.rightline.o.y += offset * Math.cos(theta);
      linesCloned.rightline.d.x -= offset * Math.sin(theta);
      linesCloned.rightline.d.y += offset * Math.cos(theta);
    }

    const xPoints = this._findCrossPoints(point, linesCloned);

    // if xPoints is odd then point is inside the object
    return xPoints !== 0 && xPoints % 2 === 1;
  }

  pointIsInsideArtboard(point, lines, absolute, calculate) {
    const coords = this._getCoords(absolute, calculate);
    lines = lines || this._getImageLines(coords);

    const xPoints = this._findCrossPoints(point, lines);

    // if xPoints is odd then point is inside the object
    return xPoints !== 0 && xPoints % 2 === 1;
  }

  /**
   * Checks if the object contains the midpoint between canvas extremities
   * Does not make sense outside the context of isOnScreen and isPartiallyOnScreen
   * @private
   * @param {fabric.Point} pointTL Top Left point
   * @param {fabric.Point} pointBR Top Right point
   * @param {Boolean} calculate use coordinates of current position instead of .oCoords
   * @return {Boolean} true if the object contains the point
   */
  /** @ts-ignore */
  _containsCenterOfCanvas(pointTL, pointBR, calculate) {
    // worst case scenario the object is so big that contains the screen
    const centerPoint = {
      x: (pointTL.x + pointBR.x) / 2,
      y: (pointTL.y + pointBR.y) / 2,
    };

    if (this.pointIsInsideArtboard(centerPoint, null, true, calculate)) {
      return true;
    }

    return false;
  }

  /**
   * Function used to search inside objects an object that contains pointer in bounding box or that contains pointerOnCanvas when painted
   * @param {Array} [objects] objects array to look into
   * @param {Object} [pointer] x,y object of point coordinates we want to check.
   * @return {fabric.Object} object that contains pointer
   * @private
   */
  /** @ts-ignore */
  _searchPossibleTargets(objects, pointer) {
    // Cache all targets where their bounding box contains point.
    var target,
      i = objects.length,
      subTarget;

    // Do not check for currently grouped objects, since we check the parent group itself.
    // until we call this function specifically to search inside the activeGroup
    while (i--) {
      const objToCheck = objects[i];
      const pointerToUse = objToCheck.group
        ? this._normalizePointer(objToCheck.group, pointer)
        : pointer;

      if (this._checkTarget(pointerToUse, objToCheck, pointer)) {
        target = objects[i];

        if (target.subTargetCheck && target instanceof fabric.Group) {
          subTarget = this._searchPossibleTargets(target._objects, pointer);

          if (subTarget && subTarget.evented && subTarget.visible) {
            this.targets.push(subTarget);
          }
        }

        break;
      }
    }

    return target;
  }

  /**
   * Checks point is inside the object.
   * @param {Object} [pointer] x,y object of point coordinates we want to check.
   * @param {fabric.Object} obj Object to test against
   * @return {Boolean} true if point is contained within an area of given object
   * @private
   */
  _checkTarget(pointer, obj) {
    if (obj?.visible && obj?.evented && obj?.containsPoint(pointer)) {
      return true;
    }
  }

  /**
   * Moves an object up in stack of drawn objects
   * @return {fabric.Object} thisArg
   * @chainable
   */
  bringForward() {
    const nextObject =
      this.canvas._objects[this.getZIndex() + this.children.length + 1];

    if (nextObject) {
      if (nextObject.type === "artboard") {
        this.moveTo(
          this.getZIndex() +
            this.children.length +
            nextObject.children.length +
            1
        );
      } else {
        this.moveTo(this.getZIndex() + this.children.length + 1);
      }

      this.children.forEach((child, index) => {
        child.moveTo(this.getZIndex() + index);
      });
    }

    return this;
  }

  /**
   * Moves an object to the top of the stack of drawn objects
   * @return {fabric.Object} thisArg
   * @chainable
   */
  bringToFront() {
    if (this.group) {
      fabric.StaticCanvas.prototype.bringToFront.call(this.group, this);
    } else if (this.canvas) {
      this.canvas.bringToFront(this);
    }

    this.forEachChild((child) => {
      child.moveTo(this.getZIndex() + child.getZIndex());
    });

    return this;
  }

  /**
   * Moves an object down in stack of drawn objects
   * @return {fabric.Object} thisArg
   * @chainable
   */
  sendBackwards() {
    const prevObject = this.canvas._objects[this.getZIndex() - 1];

    if (prevObject) {
      const prevArtboard = prevObject.__parentArtboard;

      if (prevArtboard && prevArtboard !== this) {
        const newIndex = this.getZIndex() - 1 - prevArtboard.children.length;
        this.moveTo(newIndex);
      } else if (this.getZIndex() > 0) {
        this.moveTo(this.getZIndex() - 1);
      }

      this.children.forEach((child, index) => {
        child.moveTo(this.getZIndex() + index + 1);
      });
    }

    return this;
  }

  /**
   * Moves an object to the bottom of the stack of drawn objects
   * @return {fabric.Object} thisArg
   * @chainable
   */
  sendToBack() {
    if (this.group) {
      fabric.StaticCanvas.prototype.sendToBack.call(this.group, this);
    } else if (this.canvas) {
      this.canvas.sendToBack(this);
    }

    this.forEachChild((child, index) => {
      child.moveTo(index + 1);
    });

    return this;
  }

  _toSVG(reviver) {
    var svgString = ["<g ", "COMMON_PARTS", " >\n"];

    this.forEachChild((child) => {
      svgString.push("\t\t", child.toSVG(reviver));
    });

    svgString.push("</g>\n");

    return svgString;
  }

  async addHeader() {
    let headerElements = [];
    // add objects relative to parent artboard postition
    getHeaderElementTypes().forEach((objectType) => {
      let headerItem = this.canvas
        .getObjects()
        .find((o) => o.objectType === objectType);
      headerElements.push(headerItem);
    });
    let clonedList = [];
    for (const element of headerElements) {
      clonedList.push(await cloneObject(element));
    }
    let selection = new fabric.ActiveSelection(clonedList, {
      left: this.left,
      top: this.top,
    });
    selection.destroy();
    let index = 0;
    clonedList.forEach((child) => {
      this.insertAt(child, index);
      index++;
    });
    return clonedList;
  }

  /**
   * Returns object representation of an instance
   * @param {Array} [propertiesToInclude] Any properties that you might want to additionally include in the output
   * @return {Object} object representation of an instance
   */
  toObject(properties = []) {
    const _includeDefaultValues = this.includeDefaultValues;

    const childrenToObject = [];
    this.getChildren().forEach((child) => {
      if (child) {
        const originalDefaults = child.includeDefaultValues;
        child.includeDefaultValues = _includeDefaultValues;

        const _child = child.toObject(
          propertiesToInclude.concat(props).concat(properties)
        );

        if (child.group?.type === "activeSelection") {
          const transform = fabric.util.multiplyTransformMatrices(
            child.group.calcOwnMatrix(),
            child.calcOwnMatrix()
          );
          const options = fabric.util.qrDecompose(transform);
          const center = new fabric.Point(
            options.translateX,
            options.translateY
          );

          _child.flipX = false;
          _child.flipY = false;
          _child.scaleX = options.scaleX;
          _child.scaleY = options.scaleY;
          _child.skewX = options.skewX;
          _child.skewY = options.skewY;
          _child.angle = options.angle;

          const centerPoint = this.translateToCenterPoint(
            center,
            "center",
            "center"
          );
          const position = this.translateToOriginPoint(
            centerPoint,
            _child.originX,
            _child.originY
          );
          _child.left = position.x;
          _child.top = position.y;
        }

        child.includeDefaultValues = originalDefaults;

        childrenToObject.push(_child);
      }
    });

    const artboard = fabric.Object.prototype.toObject.call(
      this,
      propertiesToInclude.concat(props).concat(properties)
    );
    artboard.children = childrenToObject;

    return artboard;
  }
};

/*
 * Returns {@link fabric.Group} instance from an object representation
 * @static
 * @memberOf fabric.Group
 * @param {Object} object Object to create a group from
 * @param {Function} [callback] Callback to invoke when an group instance is created
 */
fabric.Artboard.fromObject = async function (object, callback) {
  const children = object.children.filter(({ width, height, left, top }) => {
    return !(
      isNaN(width) ||
      !width ||
      isNaN(height) ||
      !height ||
      isNaN(left) ||
      isNaN(top)
    );
  });

  const options = cloneDeep(object);
  delete options.children;

  fabric.util.enlivenObjects(children, function (enlivenedChildren) {
    const options = cloneDeep(object);
    options.children = enlivenedChildren;

    if (options.isComponent || options.isSample) {
      // options.padding = 5;
      options.boundsStrokeColor = "#914EF9";
      options.borderColor = "#914EF9";
      options.cornerStrokeColor = "#914EF9";
      options.hoverStrokeColor = "#914EF9";
    }

    const newArtboard = new fabric.Artboard(options);

    if (newArtboard.isComponent || options.isSample) {
      const componentControls = { ...fabric.Artboard.prototype.controls };
      delete componentControls.exportArtboardControl;
      delete componentControls.playAnimationControl;

      newArtboard.controls = componentControls;
    }

    callback && callback(newArtboard);
  });
};

export function addNewArtboard(options) {
  options = cloneDeep(options);
  delete options.children;
  return new fabric.Artboard({
    ...options,
  });
}
