import React, { useContext, useEffect, useRef, useState } from "react";
import PageHeader from "../shared/PageHeader";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import AnimationThumbnail from "./AnimationThumbnail";
import {
  END_TRANSITION_INDEX,
  END_TRANSITIONS,
  getDefaultEasing,
  MIDDLE_TRANSITION_INDEX,
  reCalculateTransitionTime,
  reInitKeyframes,
  START_TRANSITION_INDEX,
  START_TRANSITIONS,
  transitionIndex,
  transitionPositions,
} from "../Animations/utils";
import { useNavigate } from "react-router";
import _ from "lodash";
import Slider from "../shared/Slider";
import TextBtn from "../shared/TextBtn";
import { cloneDeep } from "lodash";
import { withModifiedEvent } from "../canvas/utils/helper";
import { CanvasStore } from "../canvas/store/canvasStore";

function AnimationControl({
  activeAnimation,
  onMouseEnter,
  onMouseLeave,
  transitionDirection,
  handleChangeDirection,
  handleTransitionDurationChange,
  transitionLength,
  layerDuration,
}) {
  const handleMouseEnter = (direction) => {
    onMouseEnter(direction);
  };
  const handleMouseLeave = (direction) => {
    onMouseLeave(direction);
  };
  return (
    <div
      className="rounded-lg w-full mb-4 col-span-3"
      style={{ border: "1px solid rgb(223, 224, 229)" }}
    >
      {!!activeAnimation.direction && (
        <div
          className={
            "p-4 flex items-center border-0 border-b  border-ui-02  border-solid   "
          }
        >
          <span className={"text-sm mr-auto"}>Direction</span>
          <div className="flex gap-2">
            {activeAnimation.directionOptions.map((direction) => (
              <TextBtn
                text={direction}
                onMouseEnter={() => handleMouseEnter(direction)}
                onMouseLeave={() => handleMouseLeave(direction)}
                isActive={transitionDirection === direction}
                onClick={() => handleChangeDirection(direction)}
              />
            ))}
          </div>
        </div>
      )}

      <div className="px-4 py-2 flex items-center justify-between">
        <span className={"text-sm mr-2"}>Duration</span>
        <Slider
          onChange={handleTransitionDurationChange}
          value={transitionLength}
          step={0.1}
          max={layerDuration}
          min={0}
          marks={[{ value: layerDuration }]}
          unit={"s"}
        />
      </div>
    </div>
  );
}

const AnimationPanel = () => {
  const { activeCanvas, activeObject: object } = useContext(CanvasStore);
  const [tabValue, setValue] = React.useState(0);
  const [activeAnimation, setActiveAnimation] = useState({ id: -1 });
  const [transitionDirection, setTransitionDirection] = useState("left");
  const [transitionLength, setTransitionLength] = useState(1);
  const [layerDuration, setLayerDuration] = useState(0);
  const originalTransitions = useRef(null);

  const router = useNavigate();

  useEffect(() => {
    if (activeCanvas) {
      activeCanvas.on("object:modified", handleUpdateLayerDuration);
    }
    return () => {
      if (activeCanvas) {
        activeCanvas.off("object:modified", handleUpdateLayerDuration);
      }
    };
  }, [activeCanvas, object]);
  useEffect(() => {
    if (object) {
      updateLayerDuration(object);
      setActiveAnimation(object.transitions[tabValue] || { id: -1 });
    }
  }, [object, tabValue]);
  function handleUpdateLayerDuration({ target, action }) {
    if (target._id === object._id && action === "animationPropChanged")
      updateLayerDuration(object);
  }
  function updateLayerDuration(object) {
    setLayerDuration(Math.abs(object.startTime - object.endTime));
  }
  const onClose = () => {
    router(-1);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeDirection = (direction) => {
    setTransitionDirection(direction);

    handleTransitionChange({ ...activeAnimation, direction });
  };
  const handleTransitionChange = (animation) => {
    if (!object) return;
    let updatedTransition = {
      ...animation,
      transitionPosition: transitionPositions[tabValue],
      easing: getDefaultEasing(animation),
    };
    let original = {
      transitions: [...cloneDeep(object.transitions)],
    };
    withModifiedEvent(
      object,
      activeCanvas,
      () => {
        object.transitions[tabValue] = {
          ...object.transitions[tabValue],
          ...updatedTransition,
        };
        if (!updatedTransition.directionOptions) {
          delete object.transitions[tabValue]["direction"];
          delete object.transitions[tabValue]["directionOptions"];
        }

        reInitKeyframes(object);
      },
      ["transitions"],
      "transitionChange",
      original
    )();
  };

  const handleSelectTransition = (animation) => {
    originalTransitions.current = cloneDeep(animation);

    setActiveAnimation(animation);
    handleTransitionChange(animation);

    let transition = object.transitions[tabValue];
    setTransitionLength(transition.duration || 1);
  };
  const handleTransitionDurationChange = (newDuration) => {
    setTransitionLength(newDuration);
    let transition = activeAnimation;
    let transitions = object.transitions;
    let original = {
      transitions: [...cloneDeep(transitions)],
    };
    transition = transitions[tabValue];

    withModifiedEvent(
      object,
      activeCanvas,
      () => {
        switch (transition.transitionPosition) {
          case "START":
          case "END": {
            setTransitionDuration(transition, newDuration);
            break;
          }
        }
        updateMiddleTransitionDuration(transitions);
        updateTransitionFrames(object);
      },
      ["transitions"],
      "transitionChange",
      original
    )();
  };
  const getTransitionMaxLimit = (transition) => {
    if (
      transition.transitionPosition !==
      transitionPositions[MIDDLE_TRANSITION_INDEX]
    ) {
      return (
        object.objectTransitionDuration -
        object.transitions[
          transitionIndex[transition.transitionPosition] === 0
            ? END_TRANSITION_INDEX
            : START_TRANSITION_INDEX
        ].duration
      );
    }
    return object.objectTransitionDuration;
  };

  const setTransitionDuration = (transition, newDuration) => {
    if (newDuration <= 0) newDuration = 0.0001;
    let maxValue = getTransitionMaxLimit(transition);
    if (newDuration >= maxValue) {
      newDuration = maxValue - 0.0001;
    }
    transition.duration = newDuration;
  };
  const updateTransitionFrames = _.debounce((object) => {
    reCalculateTransitionTime(object);
    reInitKeyframes(object);
  }, 200);
  const updateMiddleTransitionDuration = (transitions) => {
    transitions[MIDDLE_TRANSITION_INDEX].duration =
      object.objectTransitionDuration -
      (transitions[START_TRANSITION_INDEX].duration +
        transitions[END_TRANSITION_INDEX].duration);
  };
  const handleTempTransitionChange = (animation, playAnimation = true) => {
    if (!object) return;

    activeCanvas.fire("cancel-animation");

    let updatedTransition = cloneDeep({
      ...animation,
      transitionPosition: transitionPositions[tabValue],
      easing: getDefaultEasing(animation),
    });
    originalTransitions.current = cloneDeep(object.transitions[tabValue]);
    object.transitions[tabValue] = {
      ...object.transitions[tabValue],
      ...updatedTransition,
    };
    if (!updatedTransition.directionOptions) {
      delete object.transitions[tabValue]["direction"];
      delete object.transitions[tabValue]["directionOptions"];
    }

    reInitKeyframes(object);
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (playAnimation)
        activeCanvas.fire("play-temp-animation", {
          time: object.transitions[tabValue].startTime * 1000,
          limit: 1000,
        });
    }, 200);
  };

  const timer = useRef();

  const handleTempTransitionRevert = () => {
    clearTimeout(timer.current);
    if (originalTransitions.current)
      handleTempTransitionChange(originalTransitions.current, false);
  };

  let animationsList = [];
  switch (tabValue) {
    case 0:
      animationsList = START_TRANSITIONS;
      break;
    case 2:
      animationsList = END_TRANSITIONS;
      break;
    case 3:
      animationsList = START_TRANSITIONS;
  }
  let selectedAnimationIndex = animationsList.findIndex(
    (t) => activeAnimation?.id === t.id
  );
  if (selectedAnimationIndex > -1) {
    let inc = 3 - (selectedAnimationIndex % 3);
    selectedAnimationIndex += inc;
  }
  const handlePositionMouseEnter = (direction) => {
    handleTempTransitionChange({ ...cloneDeep(activeAnimation), direction });
  };

  return (
    <div>
      <PageHeader
        heading="Animation"
        onClicked={onClose}
        showBackButton={true}
      />
      <Box sx={{ borderBottom: 1, borderColor: "#e1e1e3" }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="basic tabs example"
        >
          <Tab value={0} label="In" {...a11yProps(0)} />
          <Tab value={2} label="Out" {...a11yProps(2)} />
          {/*<Tab value={3} label="Loop" {...a11yProps(3)} />*/}
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>
        <div className={"grid grid-cols-3 gap-3"}>
          {START_TRANSITIONS.slice(0, selectedAnimationIndex).map(
            (animation) => (
              <AnimationThumbnail
                onMouseEnter={handleTempTransitionChange}
                onMouseLeave={handleTempTransitionRevert}
                key={animation.id}
                tabValue={tabValue}
                object={object}
                onClick={handleSelectTransition}
                isActive={activeAnimation.id === animation.id}
                animation={animation}
              />
            )
          )}

          {activeAnimation && activeAnimation.title !== "Static" && (
            <AnimationControl
              onMouseEnter={handlePositionMouseEnter}
              onMouseLeave={handleTempTransitionRevert}
              transitionDirection={transitionDirection}
              activeAnimation={activeAnimation}
              handleChangeDirection={handleChangeDirection}
              handleTransitionDurationChange={handleTransitionDurationChange}
              transitionLength={transitionLength}
              layerDuration={layerDuration}
            />
          )}
          {START_TRANSITIONS.slice(selectedAnimationIndex).map((animation) => (
            <AnimationThumbnail
              onMouseEnter={handleTempTransitionChange}
              onMouseLeave={handleTempTransitionRevert}
              tabValue={tabValue}
              object={object}
              key={animation.id}
              onClick={handleSelectTransition}
              isActive={activeAnimation.id === animation.id}
              animation={animation}
            />
          ))}
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <div className={"grid grid-cols-3 gap-3"}>
          {END_TRANSITIONS.slice(0, selectedAnimationIndex).map((animation) => (
            <AnimationThumbnail
              onMouseEnter={handleTempTransitionChange}
              onMouseLeave={handleTempTransitionRevert}
              key={animation.id}
              tabValue={tabValue}
              object={object}
              onClick={handleSelectTransition}
              isActive={activeAnimation.id === animation.id}
              animation={animation}
            />
          ))}

          {activeAnimation && activeAnimation.title !== "Static" && (
            <AnimationControl
              onMouseEnter={handlePositionMouseEnter}
              onMouseLeave={handleTempTransitionRevert}
              transitionDirection={transitionDirection}
              activeAnimation={activeAnimation}
              handleChangeDirection={handleChangeDirection}
              handleTransitionDurationChange={handleTransitionDurationChange}
              transitionLength={transitionLength}
              layerDuration={layerDuration}
            />
          )}
          {END_TRANSITIONS.slice(selectedAnimationIndex).map((animation) => (
            <AnimationThumbnail
              onMouseEnter={handleTempTransitionChange}
              onMouseLeave={handleTempTransitionRevert}
              tabValue={tabValue}
              object={object}
              key={animation.id}
              onClick={handleSelectTransition}
              isActive={activeAnimation.id === animation.id}
              animation={animation}
            />
          ))}
        </div>
      </CustomTabPanel>
      {/*  <CustomTabPanel value={tabValue} index={3}>
                Item Three
            </CustomTabPanel>
*/}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

export default AnimationPanel;
